import React from 'react';
import { motion } from 'framer-motion';
import { FeatureCard } from './features/FeatureCard';
import { features } from './features/featureData';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2
    }
  }
};

const MTX4Features = () => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
    >
      {features.map((feature, index) => (
        <FeatureCard
          key={index}
          {...feature}
          index={index}
        />
      ))}
    </motion.div>
  );
};

export default MTX4Features;