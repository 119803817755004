import React from 'react';
import { Users, ChevronRight, TrendingUp, Shield, Clock } from 'lucide-react';
import Button from '../../ui/Button';

const features = [
  {
    icon: TrendingUp,
    title: "Follow Top Traders",
    description: "Copy successful traders automatically and learn from their strategies"
  },
  {
    icon: Shield,
    title: "Risk Management",
    description: "Set your own risk levels and investment amounts"
  },
  {
    icon: Clock,
    title: "Real-Time Sync",
    description: "Trades are copied instantly with minimal latency"
  }
];

const CopyTradingContent: React.FC = () => {
  return (
    <div>
      <div className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6">
        <Users className="h-4 w-4 mr-2" />
        Copy Trading • HankoX
      </div>

      <h2 className="text-4xl md:text-5xl font-bold text-white mb-6 leading-tight">
        Copy Trade with{' '}
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600">
          Professional Traders
        </span>
      </h2>

      <p className="text-gray-300 text-lg mb-8 leading-relaxed">
        Start copying successful traders instantly. Perfect for both new and experienced traders looking to diversify their trading strategy. Get access to a community of professional traders and replicate their success.
      </p>

      <div className="space-y-6 mb-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex items-start gap-4 group hover:translate-x-1 transition-transform duration-300"
          >
            <div className="flex-shrink-0 p-2 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-lg group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
              <feature.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
            </div>
            <div>
              <h3 className="text-white font-semibold mb-1">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>

      <Button 
        className="group"
        icon={ChevronRight}
      >
        Start Copy Trading
      </Button>

      <div className="mt-8 flex flex-wrap gap-3">
        <span className="px-3 py-1 bg-blue-500/10 rounded-full text-blue-400 text-sm border border-blue-500/20">
          100+ Professional Traders
        </span>
        <span className="px-3 py-1 bg-purple-500/10 rounded-full text-purple-400 text-sm border border-purple-500/20">
          Automated Copying
        </span>
        <span className="px-3 py-1 bg-green-500/10 rounded-full text-green-400 text-sm border border-green-500/20">
          Risk Management
        </span>
      </div>
    </div>
  );
};

export default CopyTradingContent;