import React from 'react';
import { motion } from 'framer-motion';
import Button from '../../ui/Button';

const HeroActions = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.3 }}
      className="flex flex-col sm:flex-row items-center justify-center gap-4 px-4"
    >
      <Button 
        size="lg" 
        className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
      >
        Start Trading Now
      </Button>
      <Button
        variant="secondary"
        size="lg"
        className="w-full sm:w-auto min-w-[200px]"
      >
        Download MTX4
      </Button>
    </motion.div>
  );
};

export default HeroActions;