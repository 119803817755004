import React from 'react';
import { motion } from 'framer-motion';
import { Quote, Star, ArrowRight } from 'lucide-react';

const testimonials = [
  {
    text: "Trading with HankoX has been a game-changer. The platform's speed and reliability are unmatched.",
    author: "David K.",
    role: "Professional Trader",
    rating: 5,
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=200&h=200&fit=crop"
  },
  {
    text: "The platform's advanced charting tools and ultra-low spreads have significantly improved my trading performance.",
    author: "Sarah M.",
    role: "Day Trader",
    rating: 5,
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=200&h=200&fit=crop"
  },
  {
    text: "Best trading conditions I've experienced. The execution speed is incredible.",
    author: "Michael R.",
    role: "Forex Trader",
    rating: 5,
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=200&h=200&fit=crop"
  }
];

const Testimonials = () => {
  return (
    <div className="mb-16">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 * index }}
            className="relative group"
          >
            {/* Animated gradient background */}
            <motion.div
              className="absolute -inset-px rounded-xl bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-xl opacity-50 group-hover:opacity-100"
              animate={{
                backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                ease: "linear"
              }}
            />

            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 h-full">
              {/* Quote icon with glow */}
              <div className="relative mb-6">
                <div className="absolute -inset-2 bg-blue-500/20 rounded-full blur-lg opacity-50" />
                <Quote className="h-8 w-8 text-blue-400 relative" />
              </div>

              {/* Testimonial text */}
              <p className="text-gray-300 mb-6 text-lg leading-relaxed">
                {testimonial.text}
              </p>

              {/* Author info */}
              <div className="flex items-center gap-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.author}
                  className="w-12 h-12 rounded-full border-2 border-blue-500/20"
                />
                <div>
                  <div className="text-white font-medium">{testimonial.author}</div>
                  <div className="text-gray-400 text-sm">{testimonial.role}</div>
                </div>
              </div>

              {/* Rating */}
              <div className="absolute top-6 right-6 flex">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star 
                    key={i} 
                    className="h-4 w-4 text-yellow-400 fill-current" 
                  />
                ))}
              </div>

              {/* Hover effect */}
              <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-transparent via-blue-500/10 to-transparent opacity-0 group-hover:opacity-100 -translate-x-full group-hover:translate-x-full transition-all duration-1000" />
            </div>
          </motion.div>
        ))}
      </div>

      {/* View More Link */}
      <motion.a
        href="#"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8 }}
        className="flex items-center justify-center gap-2 text-blue-400 hover:text-blue-300 mt-8 group"
      >
        View More Reviews
        <ArrowRight className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
      </motion.a>
    </div>
  );
};

export default Testimonials;