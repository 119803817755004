import React from 'react';
import { motion } from 'framer-motion';
import VideoCloseButton from './VideoCloseButton';
import VideoIframe from './VideoIframe';

interface VideoContainerProps {
  onClose: () => void;
  embedUrl: string;
}

const VideoContainer: React.FC<VideoContainerProps> = ({ onClose, embedUrl }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95, y: 20 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.95, y: 20 }}
      transition={{ duration: 0.3 }}
      className="relative w-full max-w-5xl bg-black rounded-xl overflow-hidden shadow-2xl"
    >
      <VideoCloseButton onClose={onClose} />
      <div className="relative pt-[56.25%] bg-black">
        <VideoIframe embedUrl={embedUrl} />
      </div>
    </motion.div>
  );
};

export default VideoContainer;