import React from 'react';
import { motion } from 'framer-motion';

const HeroBackground = () => {
  return (
    <>
      {/* Base layer - Dark gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-gray-900 to-black" />

      {/* Grid Pattern with mask */}
      <div className="absolute inset-0">
        <motion.div 
          className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.03)_1px,transparent_1px)] bg-[size:32px_32px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 1.5 }}
        />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,transparent_0%,rgba(0,0,0,0.8)_70%)]" />
      </div>

      {/* Multiple Glowing Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Large Orbs */}
        {[...Array(5)].map((_, i) => (
          <motion.div
            key={`large-orb-${i}`}
            className={`absolute w-96 h-96 rounded-full blur-[100px] ${
              i % 2 === 0 ? 'bg-blue-500/20' : 'bg-purple-500/20'
            }`}
            style={{
              left: `${(i * 25) - 25}%`,
              top: `${Math.sin(i) * 50}%`,
            }}
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.1, 0.2, 0.1],
              x: [0, 50, 0],
              y: [0, 30, 0],
            }}
            transition={{
              duration: 8 + i * 2,
              repeat: Infinity,
              ease: "easeInOut",
              delay: i * 0.5
            }}
          />
        ))}

        {/* Medium Orbs */}
        {[...Array(8)].map((_, i) => (
          <motion.div
            key={`medium-orb-${i}`}
            className={`absolute w-64 h-64 rounded-full blur-[80px] ${
              i % 3 === 0 ? 'bg-cyan-500/20' : i % 3 === 1 ? 'bg-indigo-500/20' : 'bg-violet-500/20'
            }`}
            style={{
              left: `${(i * 15) - 15}%`,
              top: `${Math.cos(i) * 40}%`,
            }}
            animate={{
              scale: [1.1, 0.9, 1.1],
              opacity: [0.15, 0.25, 0.15],
              x: [30, -30, 30],
              y: [20, -20, 20],
            }}
            transition={{
              duration: 6 + i,
              repeat: Infinity,
              ease: "easeInOut",
              delay: i * 0.3
            }}
          />
        ))}

        {/* Small Orbs */}
        {[...Array(12)].map((_, i) => (
          <motion.div
            key={`small-orb-${i}`}
            className="absolute w-32 h-32 rounded-full blur-[50px] bg-gradient-to-r from-blue-500/20 to-purple-500/20"
            style={{
              left: `${(i * 10) - 10}%`,
              top: `${Math.tan(i) * 30}%`,
            }}
            animate={{
              scale: [0.9, 1.1, 0.9],
              opacity: [0.1, 0.2, 0.1],
              x: [-20, 20, -20],
              y: [-15, 15, -15],
            }}
            transition={{
              duration: 4 + i * 0.5,
              repeat: Infinity,
              ease: "easeInOut",
              delay: i * 0.2
            }}
          />
        ))}
      </div>

      {/* Animated gradient overlay */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-blue-500/5"
        animate={{
          backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
        }}
        transition={{
          duration: 15,
          repeat: Infinity,
          ease: "linear"
        }}
      />

      {/* Particles */}
      <div className="absolute inset-0">
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={`particle-${i}`}
            className="absolute w-1 h-1 bg-blue-500/30 rounded-full"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, -100],
              opacity: [0, 1, 0],
            }}
            transition={{
              duration: 2 + Math.random() * 2,
              repeat: Infinity,
              delay: Math.random() * 2,
              ease: "easeOut"
            }}
          />
        ))}
      </div>
    </>
  );
};

export default HeroBackground;