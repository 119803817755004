import React from 'react';
import { motion } from 'framer-motion';
import { ExternalLink, Check, Info } from 'lucide-react';
import VideoModal from '../../ui/video/VideoModal';

const features = [
  'Same powerful features as MetaTrader 4',
  'MagnoFX customizations and branding',
  'Seamless integration with your account',
  'Full compatibility with MT4 indicators'
];

const MTX4NotificationContent: React.FC = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState(false);

  return (
    <>
      <div className="space-y-4">
        <motion.h3 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-2xl font-bold text-white"
        >
          MTX4 is a Custom-Branded MetaTrader 4 Platform*
        </motion.h3>
        
        <motion.p
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-gray-300 leading-relaxed"
        >
          Experience the industry-standard MetaTrader 4 platform with MagnoFX's unique customizations. 
          Get all the powerful features you know and love, enhanced with our professional trading environment.
        </motion.p>

        {/* Important Notice - Adjusted spacing */}
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.25 }}
          className="flex items-start gap-3 p-4 bg-blue-500/10 rounded-lg border border-blue-500/20"
        >
          <Info className="h-5 w-5 text-blue-400 flex-shrink-0 mt-0.5" />
          <div className="text-sm text-blue-300">
            <span className="font-medium">Important Note:</span> MTX4 is a standalone platform and does not work with MT4 logins or MetaTrader apps from the app store. You must use MTX4 specifically for MagnoFX accounts.
          </div>
        </motion.div>

        {/* Features - Adjusted spacing */}
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="space-y-2.5"
        >
          {features.map((feature, index) => (
            <div key={index} className="flex items-start gap-3 group">
              <div className="flex-shrink-0 p-1 bg-blue-500/10 rounded group-hover:bg-blue-500/20 transition-colors">
                <Check className="h-4 w-4 text-blue-500" />
              </div>
              <span className="text-gray-300 group-hover:text-white transition-colors">
                {feature}
              </span>
            </div>
          ))}
        </motion.div>
        
        <motion.button
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          onClick={() => setIsVideoModalOpen(true)}
          className="flex items-center gap-2 text-sm text-blue-400 hover:text-blue-300 transition-colors pt-2 group"
        >
          <ExternalLink className="h-4 w-4 group-hover:scale-110 transition-transform" />
          <span className="border-b border-blue-400/0 group-hover:border-blue-400/100 transition-colors">
            Powered by MetaTrader 4 Technology
          </span>
        </motion.button>
      </div>

      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoUrl="https://youtu.be/EoTElYZf5Go"
      />
    </>
  );
};

export default MTX4NotificationContent;