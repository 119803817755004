import React from 'react';
import { motion } from 'framer-motion';
import { ArrowUpCircle, ArrowDownCircle, Timer, AlertCircle, Target } from 'lucide-react';
import { GradientText } from '../../ui/GradientText';

const orderTypes = [
  {
    type: 'Buy Limit',
    icon: ArrowUpCircle,
    description: 'Place an order to buy at a price lower than the current market price',
    example: 'Current price: 1.2000, Buy Limit: 1.1950',
    color: 'from-green-500/20 to-emerald-500/20',
    textColor: 'text-green-400'
  },
  {
    type: 'Sell Limit',
    icon: ArrowDownCircle,
    description: 'Place an order to sell at a price higher than the current market price',
    example: 'Current price: 1.2000, Sell Limit: 1.2050',
    color: 'from-red-500/20 to-pink-500/20',
    textColor: 'text-red-400'
  },
  {
    type: 'Buy Stop',
    icon: ArrowUpCircle,
    description: 'Place an order to buy at a price higher than the current market price',
    example: 'Current price: 1.2000, Buy Stop: 1.2050',
    color: 'from-blue-500/20 to-cyan-500/20',
    textColor: 'text-blue-400'
  },
  {
    type: 'Sell Stop',
    icon: ArrowDownCircle,
    description: 'Place an order to sell at a price lower than the current market price',
    example: 'Current price: 1.2000, Sell Stop: 1.1950',
    color: 'from-purple-500/20 to-violet-500/20',
    textColor: 'text-purple-400'
  }
];

const PendingOrders = () => {
  return (
    <section id="pending-orders" className="scroll-mt-20">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
          Understanding <GradientText>Pending Orders</GradientText>
        </h2>
        <p className="text-gray-400 text-lg">
          Learn how to use pending orders to automate your trading entries
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        {orderTypes.map((order, index) => (
          <motion.div
            key={order.type}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className="relative group"
          >
            <div className={`absolute inset-0 rounded-xl bg-gradient-to-r ${order.color} blur-xl opacity-50 group-hover:opacity-100 transition-opacity`} />
            
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 h-full">
              <div className="flex items-center gap-4 mb-6">
                <div className={`flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br ${order.color}`}>
                  <order.icon className={`h-6 w-6 ${order.textColor}`} />
                </div>
                <h3 className="text-xl font-semibold text-white">{order.type}</h3>
              </div>
              
              <p className="text-gray-300 mb-4">{order.description}</p>
              
              <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-700">
                <div className="flex items-center gap-2 text-sm text-gray-400 mb-2">
                  <Timer className="h-4 w-4" />
                  <span>Example:</span>
                </div>
                <p className={`${order.textColor} font-mono`}>{order.example}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Tips Section */}
      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
        <div className="flex items-center gap-3 mb-6">
          <AlertCircle className="h-6 w-6 text-blue-400" />
          <h3 className="text-xl font-semibold text-white">Important Tips</h3>
        </div>
        <ul className="space-y-4">
          <li className="flex items-start gap-3 text-gray-300">
            <Target className="h-5 w-5 text-blue-400 mt-1" />
            <span>Always set your pending orders at key support and resistance levels</span>
          </li>
          <li className="flex items-start gap-3 text-gray-300">
            <Target className="h-5 w-5 text-blue-400 mt-1" />
            <span>Consider market volatility when placing pending orders</span>
          </li>
          <li className="flex items-start gap-3 text-gray-300">
            <Target className="h-5 w-5 text-blue-400 mt-1" />
            <span>Use pending orders to trade breakouts and reversals</span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default PendingOrders;