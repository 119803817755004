import React from 'react';
import { motion } from 'framer-motion';
import NumberCounter from '../../animations/NumberCounter';

const stats = [
  { number: 50, suffix: '+', label: 'Currency Pairs' },
  { number: 100, suffix: 'K+', label: 'Active Traders' },
  { number: 99.9, suffix: '%', label: 'Execution Rate' },
  { number: 24, suffix: '/7', label: 'Support' }
];

const HeroStats = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 px-4">
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 + index * 0.1 }}
          className="relative group"
          whileHover={{ y: -5 }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-blue-950/30 to-purple-950/30 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
          <div className="relative bg-gray-900/50 backdrop-blur-sm rounded-lg p-6 border border-gray-800 group-hover:border-blue-900/50">
            <div className="text-3xl font-bold text-white mb-2">
              <NumberCounter end={stat.number} suffix={stat.suffix} />
            </div>
            <div className="text-sm text-gray-400">{stat.label}</div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default HeroStats;