import React from 'react';
import { motion } from 'framer-motion';
import { ExternalLink } from 'lucide-react';
import { cn } from '../../../utils/cn';
import { useSpreadAnimation } from '../../../hooks/useSpreadAnimation';
import { getSpreadInfo } from '../../../utils/spreadUtils';

interface SpreadStatusProps {
  spread: number;
  className?: string;
}

const SpreadStatus: React.FC<SpreadStatusProps> = ({ spread: initialSpread, className }) => {
  const { spread, trend } = useSpreadAnimation({ initialSpread });
  const info = getSpreadInfo(spread);

  return (
    <div className={cn("relative", className)}>
      <div
        className={cn(
          "flex items-center justify-between p-2 sm:p-4 rounded-lg backdrop-blur-sm",
          "bg-gradient-to-r",
          info.color,
          "border",
          info.borderColor
        )}
      >
        <div className="flex items-center gap-2 sm:gap-3">
          <div className={cn("p-1.5 sm:p-2 rounded-lg bg-gradient-to-br", info.color)}>
            <motion.div 
              className={cn("h-4 w-4 sm:h-5 sm:w-5", info.textColor)}
              animate={{
                rotate: trend === 'up' ? [0, 45] : [0, -45]
              }}
              transition={{ duration: 0.3 }}
            >
              ↗
            </motion.div>
          </div>
          
          <div>
            <div className="flex items-center gap-1 sm:gap-2">
              <motion.div 
                className={cn(
                  "text-base sm:text-lg font-bold tabular-nums tracking-wider",
                  trend === 'up' ? 'text-green-400' : 'text-red-400'
                )}
                key={spread.toString()}
                initial={{ opacity: 0.5, y: trend === 'up' ? 10 : -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                {spread.toFixed(4)}
              </motion.div>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                className={cn(
                  "text-xs font-bold",
                  trend === 'up' ? 'text-green-400' : 'text-red-400'
                )}
              >
                {trend === 'up' ? '▲' : '▼'}
              </motion.div>
            </div>
            <div className="text-xs sm:text-sm text-gray-400 flex items-center gap-1 sm:gap-2">
              <motion.span 
                className={cn("w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full", info.pulseColor)}
                animate={{
                  scale: [1, 1.5, 1],
                  opacity: [1, 0.5, 1],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              {info.label} Spread
            </div>
          </div>
        </div>

        <a
          href="https://login.hankotrade.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={cn(
            "flex items-center gap-1 sm:gap-2 px-2 sm:px-4 py-1.5 sm:py-2 rounded-lg",
            "bg-gradient-to-r from-blue-600 to-blue-700",
            "text-white text-xs sm:text-sm font-medium",
            "hover:from-blue-700 hover:to-blue-800",
            "transition-all duration-300"
          )}
        >
          Trade Now
          <ExternalLink className="h-3 w-3 sm:h-4 sm:w-4" />
        </a>
      </div>

      <div
        className={cn(
          "absolute inset-0 -z-10 bg-gradient-to-r rounded-lg blur-xl opacity-30",
          info.color
        )}
      />
    </div>
  );
};

export default SpreadStatus;