import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeftRight, TrendingUp, DollarSign, Star } from 'lucide-react';
import { GradientText } from '../../ui/GradientText';

const SpreadExplanation = () => {
  return (
    <section id="understanding-spreads" className="scroll-mt-20">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
          Understanding <GradientText>Spreads</GradientText>
        </h2>
        <p className="text-gray-400 text-lg">
          Learn about the difference between bid and ask prices in forex trading
        </p>
        
        {/* Competitive Spreads Notice */}
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="mt-6 inline-flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-full border border-blue-500/20"
        >
          <Star className="h-4 w-4 text-yellow-400" />
          <span className="text-blue-400 text-sm font-medium">
            * We offer the lowest and most competitive spreads in the industry
          </span>
        </motion.div>
      </motion.div>

      {/* Rest of the component remains the same */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
        {[
          {
            icon: ArrowLeftRight,
            title: 'What is Spread?',
            description: 'The spread is the difference between the buy (ask) and sell (bid) price of a currency pair'
          },
          {
            icon: TrendingUp,
            title: 'Types of Spreads',
            description: 'Fixed spreads remain constant, while variable spreads change based on market conditions'
          },
          {
            icon: DollarSign,
            title: 'Cost of Trading',
            description: 'The spread represents the cost of trading and is how brokers make their commission'
          }
        ].map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.2 }}
            className="group relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 hover:border-blue-500/50 h-full">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6">
                <item.icon className="h-6 w-6 text-blue-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-4">{item.title}</h3>
              <p className="text-gray-400">{item.description}</p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Spread Example */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="relative group"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
        <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50">
          <h3 className="text-xl font-semibold text-white mb-6">Spread Example</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <p className="text-gray-300">
                If EUR/USD has a bid price of 1.1000 and an ask price of 1.1002:
              </p>
              <ul className="space-y-3">
                <li className="flex items-center gap-3 text-gray-300">
                  <span className="w-2 h-2 rounded-full bg-blue-400" />
                  Bid (Sell) Price: 1.1000
                </li>
                <li className="flex items-center gap-3 text-gray-300">
                  <span className="w-2 h-2 rounded-full bg-blue-400" />
                  Ask (Buy) Price: 1.1002
                </li>
                <li className="flex items-center gap-3 text-gray-300">
                  <span className="w-2 h-2 rounded-full bg-blue-400" />
                  Spread = 0.0002 (2 pips)
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <p className="text-gray-300 font-medium">What this means:</p>
              <ul className="space-y-3">
                <li className="flex items-start gap-3 text-gray-300">
                  <ArrowLeftRight className="h-5 w-5 text-blue-400 mt-0.5" />
                  You buy at the higher price (1.1002)
                </li>
                <li className="flex items-start gap-3 text-gray-300">
                  <ArrowLeftRight className="h-5 w-5 text-blue-400 mt-0.5" />
                  You sell at the lower price (1.1000)
                </li>
                <li className="flex items-start gap-3 text-gray-300">
                  <ArrowLeftRight className="h-5 w-5 text-blue-400 mt-0.5" />
                  The difference (spread) is the cost of trading
                </li>
              </ul>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default SpreadExplanation;