import React from 'react';
import Container from '../components/ui/Container';
import { GradientText } from '../components/ui/GradientText';
import { motion } from 'framer-motion';
import { BookOpen, TrendingUp, DollarSign, BarChart2, LineChart, ArrowRight, ExternalLink, Shield } from 'lucide-react';
import ForexBasics from '../components/education/forex/ForexBasics';
import OrderTypes from '../components/education/forex/OrderTypes';
import SpreadExplanation from '../components/education/forex/SpreadExplanation';
import CurrencyPairs from '../components/education/forex/CurrencyPairs';
import PendingOrders from '../components/education/forex/PendingOrders';
import StopLossTakeProfit from '../components/education/forex/StopLossTakeProfit';
import Button from '../components/ui/Button';
import { TRADING_URL } from '../utils/constants';

const ForexBasicsPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
      <Container className="pt-32 pb-16">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
          >
            <BookOpen className="h-4 w-4 mr-2" />
            Forex Education
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-4xl md:text-5xl font-bold text-white mb-6"
          >
            Understanding{' '}
            <GradientText>Forex Trading</GradientText>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-3xl mx-auto mb-8"
          >
            Learn the fundamentals of forex trading, from basic concepts to advanced trading strategies. 
            Master currency pairs, order types, and risk management techniques.
          </motion.p>

          {/* Hero CTA */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-4"
          >
            <Button
              href={TRADING_URL}
              size="lg"
              icon={ExternalLink}
              className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
            >
              Start Trading Now
            </Button>
            <Button
              href={TRADING_URL}
              variant="secondary"
              size="lg"
              className="w-full sm:w-auto min-w-[200px]"
            >
              Open Demo Account
            </Button>
          </motion.div>
        </div>

        {/* Quick Navigation */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-16">
          {[
            { icon: TrendingUp, label: 'Forex Basics' },
            { icon: BarChart2, label: 'Order Types' },
            { icon: LineChart, label: 'Understanding Spreads' },
            { icon: DollarSign, label: 'Currency Pairs' },
            { icon: TrendingUp, label: 'Pending Orders' },
            { icon: Shield, label: 'Risk Management' }
          ].map((item, index) => (
            <motion.a
              key={index}
              href={`#${item.label.toLowerCase().replace(' ', '-')}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 + index * 0.1 }}
              className="group relative"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
              <div className="relative flex items-center gap-3 p-4 bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-700/50 hover:border-blue-500/50">
                <item.icon className="h-5 w-5 text-blue-400" />
                <span className="text-white font-medium">{item.label}</span>
                <ArrowRight className="h-4 w-4 text-gray-400 group-hover:text-white ml-auto transition-colors" />
              </div>
            </motion.a>
          ))}
        </div>

        {/* Content Sections */}
        <div className="space-y-24">
          <ForexBasics />
          <OrderTypes />
          <SpreadExplanation />
          <CurrencyPairs />
          <PendingOrders />
          <StopLossTakeProfit />

          {/* Bottom CTA */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="relative group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-12 border border-gray-700/50 text-center">
              <h2 className="text-3xl font-bold text-white mb-6">
                Ready to Start{' '}
                <GradientText>Trading?</GradientText>
              </h2>
              <p className="text-gray-400 text-lg mb-8 max-w-2xl mx-auto">
                Put your forex knowledge into practice. Open an account now and start trading with HankoX.
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <Button
                  href={TRADING_URL}
                  size="lg"
                  icon={ExternalLink}
                  className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
                >
                  Open Live Account
                </Button>
                <Button
                  href={TRADING_URL}
                  variant="secondary"
                  size="lg"
                  className="w-full sm:w-auto min-w-[200px]"
                >
                  Try Demo Account
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default ForexBasicsPage;