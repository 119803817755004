import React from 'react';
import { motion } from 'framer-motion';
import { GradientText } from '../../ui/GradientText';
import Button from '../../ui/Button';
import { TRADING_URL } from '../../../utils/constants';
import Testimonials from '../Testimonials';

const TestimonialsSection = () => {
  return (
    <div className="mb-24">
      <div className="text-center mb-12">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl md:text-4xl font-bold text-white mb-4"
        >
          What Our <GradientText>Traders Say</GradientText>
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto"
        >
          Join thousands of satisfied traders who have chosen HankoX
        </motion.p>
      </div>

      <Testimonials />

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="text-center"
      >
        <Button
          href={TRADING_URL}
          size="lg"
          className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
        >
          Join Our Trading Community
        </Button>
      </motion.div>
    </div>
  );
};

export default TestimonialsSection;