import React from 'react';
import { motion } from 'framer-motion';
import { GradientText } from '../../ui/GradientText';

const HeroTitle = () => {
  return (
    <motion.h1
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1 }}
      className="text-4xl md:text-7xl font-bold text-white mb-8"
    >
      Experience{' '}
      <GradientText className="animate-gradient">
        Professional Trading
      </GradientText>
    </motion.h1>
  );
};

export default HeroTitle;