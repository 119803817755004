import React from 'react';
import Container from '../components/ui/Container';
import SponsorHero from '../components/sponsors/SponsorHero';
import InfluencerGrid from '../components/sponsors/InfluencerGrid';
import BenefitsSection from '../components/sponsors/BenefitsSection';
import ApplicationProcess from '../components/sponsors/ApplicationProcess';
import CommissionStructure from '../components/sponsors/CommissionStructure';
import SponsorCTA from '../components/sponsors/SponsorCTA';

const Sponsors = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
      <Container className="pt-32 pb-16">
        <SponsorHero />
        <InfluencerGrid />
        <BenefitsSection />
        <CommissionStructure />
        <ApplicationProcess />
        <SponsorCTA />
      </Container>
    </div>
  );
};

export default Sponsors;