import React from 'react';
import { X } from 'lucide-react';

interface VideoCloseButtonProps {
  onClose: () => void;
}

const VideoCloseButton: React.FC<VideoCloseButtonProps> = ({ onClose }) => {
  return (
    <button
      onClick={onClose}
      className="absolute top-4 right-4 z-10 p-2 text-white/80 hover:text-white bg-black/50 hover:bg-black/70 rounded-full transition-colors"
      aria-label="Close video"
    >
      <X className="h-6 w-6" />
    </button>
  );
};

export default VideoCloseButton;