import React from 'react';
import { motion } from 'framer-motion';

const pairs = [
  { base: 'EUR', quote: 'USD' },
  { base: 'GBP', quote: 'USD' },
  { base: 'USD', quote: 'JPY' },
  { base: 'USD', quote: 'CHF' },
  { base: 'AUD', quote: 'USD' },
  { base: 'USD', quote: 'CAD' }
];

const CurrencyPairs = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-12">
      {pairs.map((pair, index) => (
        <motion.div
          key={`${pair.base}${pair.quote}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 * index }}
          whileHover={{ y: -5 }}
          className="relative group"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
          <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-4 border border-gray-700/50">
            <div className="flex items-center justify-center mb-3">
              <div className="relative w-8 h-8">
                <img
                  src={`https://flagcdn.com/w40/${pair.base.toLowerCase() === 'eur' ? 'eu' : pair.base.slice(0, 2).toLowerCase()}.png`}
                  alt={pair.base}
                  className="absolute inset-0 w-full h-full object-cover rounded-full"
                />
              </div>
              <div className="mx-2 text-gray-400">/</div>
              <div className="relative w-8 h-8">
                <img
                  src={`https://flagcdn.com/w40/${pair.quote.toLowerCase() === 'eur' ? 'eu' : pair.quote.slice(0, 2).toLowerCase()}.png`}
                  alt={pair.quote}
                  className="absolute inset-0 w-full h-full object-cover rounded-full"
                />
              </div>
            </div>
            <div className="text-center text-white font-medium">
              {pair.base}/{pair.quote}
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default CurrencyPairs;