import React from 'react';
import { motion } from 'framer-motion';

const stats = [
  { value: '50+', label: 'Currency Pairs' },
  { value: '0.0', label: 'Spreads from' },
  { value: '24/7', label: 'Support' }
];

const StatsGrid = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12">
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 + index * 0.1 }}
          whileHover={{ 
            y: -5,
            transition: { duration: 0.2 }
          }}
          className="relative group"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
          <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 border border-gray-700/50">
            <motion.div 
              className="text-3xl font-bold text-white mb-2"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ 
                type: "spring",
                stiffness: 200,
                damping: 10,
                delay: 0.5 + index * 0.1 
              }}
            >
              {stat.value}
            </motion.div>
            <div className="text-sm text-gray-400">{stat.label}</div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default StatsGrid;