import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Monitor, Clock } from 'lucide-react';

const badges = [
  {
    icon: Zap,
    text: 'Ultra-Low Spreads',
    gradient: 'from-blue-600/20 to-blue-800/20',
    glowColor: 'group-hover:shadow-blue-500/25'
  },
  {
    icon: Monitor,
    text: 'Advanced Platform',
    gradient: 'from-purple-600/20 to-purple-800/20',
    glowColor: 'group-hover:shadow-purple-500/25'
  },
  {
    icon: Clock,
    text: '24/7 Support',
    gradient: 'from-green-600/20 to-green-800/20',
    glowColor: 'group-hover:shadow-green-500/25'
  }
];

const HeroBadges = () => {
  return (
    <div className="flex flex-wrap justify-center gap-4 mb-12">
      {badges.map((badge, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 + index * 0.1 }}
          className="group relative"
        >
          {/* Glow effect */}
          <div className={`absolute inset-0 rounded-full blur opacity-0 transition-all duration-300 ${badge.glowColor}`} />
          
          {/* Badge content */}
          <div className={`relative flex items-center gap-2 px-4 py-2 rounded-full backdrop-blur-sm bg-gradient-to-r ${badge.gradient} border border-gray-700/50 transition-all duration-300`}>
            <badge.icon className="h-4 w-4 text-white" />
            <span className="text-sm font-medium text-white whitespace-nowrap">{badge.text}</span>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default HeroBadges;