import React from 'react';
import Container from '../components/ui/Container';
import HeroSection from '../components/tradewithpat/HeroSection';
import ProfileImage from '../components/tradewithpat/ProfileImage';
import StatsGrid from '../components/tradewithpat/StatsGrid';
import PlatformSection from '../components/tradewithpat/sections/PlatformSection';
import MarketsSection from '../components/tradewithpat/sections/MarketsSection';
import TestimonialsSection from '../components/tradewithpat/sections/TestimonialsSection';
import YouTubeSection from '../components/tradewithpat/YouTubeSection';
import FloatingElements from '../components/tradewithpat/FloatingElements';
import BackgroundGradient from '../components/tradewithpat/BackgroundGradient';
import StickyCTA from '../components/tradewithpat/StickyCTA';

const TradeWithPat = () => {
  return (
    <div className="min-h-screen bg-black relative overflow-hidden">
      <BackgroundGradient />
      <FloatingElements />
      
      <Container className="pt-32 pb-16 relative">
        <HeroSection />
        <ProfileImage />
        <StatsGrid />
        <PlatformSection />
        <MarketsSection />
        <TestimonialsSection />
        <YouTubeSection />
      </Container>

      <StickyCTA />
    </div>
  );
};

export default TradeWithPat;