import React, { Suspense } from 'react';
import Container from '../components/ui/Container';
import PlatformHero from '../components/platform/PlatformHero';
import LoadingScreen from '../components/LoadingScreen';

// Lazy load components
const TradingFlow = React.lazy(() => import('../components/platform/TradingFlow'));
const FeatureGrid = React.lazy(() => import('../components/platform/features/FeatureGrid'));
const TradingTools = React.lazy(() => import('../components/platform/TradingTools'));
const PlatformAdvantages = React.lazy(() => import('../components/platform/PlatformAdvantages'));
const PlatformCTA = React.lazy(() => import('../components/platform/PlatformCTA'));

const HankoX = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
      <Container className="pt-24 md:pt-32 pb-16">
        {/* Hero Section - Always loaded immediately */}
        <PlatformHero />
        
        {/* Lazy loaded sections with reduced spacing */}
        <Suspense fallback={<LoadingScreen />}>
          <div className="mt-8 md:mt-12 space-y-16 md:space-y-24">
            <TradingFlow />
            <FeatureGrid />
            <TradingTools />
            <PlatformAdvantages />
            <PlatformCTA />
          </div>
        </Suspense>
      </Container>
    </div>
  );
}

export default HankoX;