// Trading platform URLs
export const TRADING_URL = 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ==';
export const DEMO_URL = 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ==';
export const MAGNOFX_URL = 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ==';
export const DOWNLOAD_URL = 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ==';
export const SUPPORT_URL = 'mailto:support@magnofx.com';
export const LIVE_CHAT_URL = 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ==';

// Social media URLs
export const SOCIAL_URLS = {
  facebook: 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ==',
  twitter: 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ==',
  instagram: 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ==',
  linkedin: 'https://login.hankotrade.com/register?franchiseLead=Mzc1OQ=='
} as const;

// Navigation URLs
export const NAV_URLS = {
  home: '/',
  markets: '/markets',
  trading: '/trading',
  about: '/about',
  contact: '/contact',
  mtx4: '/mtx4'
} as const;