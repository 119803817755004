import React from 'react';
import { motion } from 'framer-motion';

const ChristmasBackground = () => {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {/* Animated snow particles */}
      {[...Array(50)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-2 h-2 bg-white rounded-full"
          style={{
            left: `${Math.random() * 100}%`,
            top: `-${Math.random() * 20}%`,
          }}
          animate={{
            y: ['0vh', '100vh'],
            x: [0, Math.random() * 100 - 50],
          }}
          transition={{
            duration: 5 + Math.random() * 5,
            repeat: Infinity,
            ease: "linear",
            delay: -Math.random() * 10
          }}
        />
      ))}
      
      {/* Christmas colors gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-red-900/20 via-green-900/10 to-transparent" />
      
      {/* Decorative lights effect */}
      <div className="absolute top-0 left-0 right-0 h-2 bg-gradient-to-r from-yellow-500/50 via-red-500/50 to-green-500/50 animate-pulse" />
    </div>
  );
};

export default ChristmasBackground;