import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, BarChart2 } from 'lucide-react';

const sections = [
  {
    icon: LineChart,
    title: 'Advanced Trading Platform',
    description: 'Experience professional-grade trading with our cutting-edge HankoX platform featuring advanced charting tools and real-time market data.'
  },
  {
    icon: BarChart2,
    title: 'Multiple Trading Instruments',
    description: 'Trade forex, cryptocurrencies, commodities, and more all from a single unified platform with competitive spreads.'
  }
];

const ValueSections = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
      {sections.map((section, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 + index * 0.1 }}
          className="relative group"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
          <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50">
            <section.icon className="h-10 w-10 text-blue-400 mb-4" />
            <h3 className="text-2xl font-bold text-white mb-3">{section.title}</h3>
            <p className="text-gray-400">{section.description}</p>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default ValueSections;