import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, BarChart2, CandlestickChart, DollarSign } from 'lucide-react';
import { cn } from '../utils/cn';

interface LoadingScreenProps {
  onLoadingComplete?: () => void;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ onLoadingComplete }) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      onLoadingComplete?.();
    }, 2000);

    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 20);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [onLoadingComplete]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-[9999] bg-[#050810] flex items-center justify-center overflow-hidden"
      >
        {/* Background Effects */}
        <div className="absolute inset-0">
          {/* Grid Pattern */}
          <motion.div 
            className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.03)_1px,transparent_1px)] bg-[size:16px_16px] sm:bg-[size:32px_32px]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 1.5 }}
          />

          {/* Animated Gradient */}
          <motion.div
            className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-blue-500/5"
            animate={{
              backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
            }}
            transition={{
              duration: 15,
              repeat: Infinity,
              ease: "linear"
            }}
          />
        </div>

        {/* Main Content */}
        <div className="relative flex flex-col items-center px-4">
          {/* Logo Container */}
          <div className="relative mb-12">
            {/* Enhanced Glow Effect */}
            <motion.div
              className="absolute -inset-8 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 rounded-full blur-2xl"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.3, 0.5, 0.3],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
            
            {/* Logo */}
            <motion.div
              className="relative bg-black/50 backdrop-blur-sm rounded-2xl p-6 border border-gray-800/50"
              animate={{
                y: [0, -8, 0],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <motion.img
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="HankoX"
                className="h-12 sm:h-16 w-auto"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.6,
                  ease: "easeOut"
                }}
              />
            </motion.div>

            {/* Orbiting Trading Icons */}
            {[LineChart, BarChart2, CandlestickChart, DollarSign].map((Icon, index) => (
              <motion.div
                key={index}
                className="absolute"
                style={{
                  top: '50%',
                  left: '50%',
                }}
                animate={{
                  rotate: [index * 90, index * 90 + 360],
                }}
                transition={{
                  duration: 8,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                <motion.div
                  className={cn(
                    "absolute -translate-x-1/2 -translate-y-1/2",
                    "w-8 h-8 sm:w-10 sm:h-10 rounded-lg",
                    "bg-gray-800/50 backdrop-blur-sm",
                    "border border-gray-700/50",
                    "flex items-center justify-center"
                  )}
                  style={{
                    left: '80px',
                  }}
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.5, 1, 0.5],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                >
                  <Icon className="h-4 w-4 sm:h-5 sm:w-5 text-blue-400" />
                </motion.div>
              </motion.div>
            ))}
          </div>

          {/* Progress Bar */}
          <div className="w-48 sm:w-64 mb-6">
            <div className="h-1.5 bg-gray-800/50 rounded-full overflow-hidden backdrop-blur-sm">
              <motion.div
                className="h-full bg-gradient-to-r from-blue-500 to-purple-500"
                style={{
                  width: `${progress}%`,
                  backgroundSize: '200% 100%',
                }}
                animate={{
                  backgroundPosition: ['0% 50%', '100% 50%'],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
            </div>
          </div>

          {/* Loading Text */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className={cn(
              "text-gray-400 text-xs sm:text-sm",
              "font-mono tracking-wider"
            )}
          >
            Loading Professional Trading Platform
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LoadingScreen;