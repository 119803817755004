import React from 'react';
import { motion } from 'framer-motion';
import { Info } from 'lucide-react';

const MTX4NotificationBadge = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      className="inline-flex items-center gap-2 px-4 py-2 bg-blue-500/10 text-blue-400 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20"
    >
      <Info className="h-4 w-4" />
      Important Information
    </motion.div>
  );
};

export default MTX4NotificationBadge;