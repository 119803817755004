import React from 'react';
import { motion } from 'framer-motion';
import { ArrowUpCircle, ArrowDownCircle, Timer, AlertCircle } from 'lucide-react';
import { GradientText } from '../../ui/GradientText';

const OrderTypes = () => {
  return (
    <section id="order-types" className="scroll-mt-20">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
          Understanding <GradientText>Order Types</GradientText>
        </h2>
        <p className="text-gray-400 text-lg">
          Learn about different types of orders and when to use them
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {[
          {
            icon: ArrowUpCircle,
            title: 'Buy (Long) Orders',
            description: 'Purchase a currency pair expecting the price to rise',
            color: 'text-green-400',
            examples: [
              'Market Buy: Immediate purchase at current price',
              'Buy Limit: Order to buy at a lower price',
              'Buy Stop: Order to buy at a higher price'
            ]
          },
          {
            icon: ArrowDownCircle,
            title: 'Sell (Short) Orders',
            description: 'Sell a currency pair expecting the price to fall',
            color: 'text-red-400',
            examples: [
              'Market Sell: Immediate sale at current price',
              'Sell Limit: Order to sell at a higher price',
              'Sell Stop: Order to sell at a lower price'
            ]
          }
        ].map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.2 }}
            className="group relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 hover:border-blue-500/50">
              <div className="flex items-center gap-4 mb-6">
                <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20">
                  <item.icon className={`h-6 w-6 ${item.color}`} />
                </div>
                <h3 className="text-xl font-semibold text-white">{item.title}</h3>
              </div>
              <p className="text-gray-400 mb-6">{item.description}</p>
              <ul className="space-y-3">
                {item.examples.map((example, i) => (
                  <li key={i} className="flex items-start gap-3 text-gray-300">
                    <Timer className="h-5 w-5 text-blue-400 mt-0.5 flex-shrink-0" />
                    <span>{example}</span>
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default OrderTypes;