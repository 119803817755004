import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, TrendingUp, Award } from 'lucide-react';
import { GradientText } from '../ui/GradientText';

const tiers = [
  {
    name: 'Standard',
    commission: '$4',
    volume: '1-100',
    icon: DollarSign,
    color: 'from-blue-500/20 to-blue-600/20'
  },
  {
    name: 'Premium',
    commission: '$5',
    volume: '101-500',
    icon: TrendingUp,
    color: 'from-purple-500/20 to-purple-600/20'
  },
  {
    name: 'Elite',
    commission: '$6',
    volume: '500+',
    icon: Award,
    color: 'from-green-500/20 to-green-600/20'
  }
];

const CommissionStructure = () => {
  return (
    <div className="mb-24">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-white mb-4">
          Commission <GradientText>Structure</GradientText>
        </h2>
        <p className="text-gray-400 text-lg">
          Earn more as your referral volume grows
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {tiers.map((tier, index) => (
          <motion.div
            key={tier.name}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className="group relative"
          >
            <div className={`absolute inset-0 bg-gradient-to-r ${tier.color} rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity`} />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 hover:border-blue-500/50 text-center">
              <tier.icon className="h-12 w-12 text-blue-400 mx-auto mb-4" />
              <h3 className="text-2xl font-bold text-white mb-2">{tier.name}</h3>
              <div className="text-4xl font-bold text-blue-400 mb-2">
                {tier.commission}
                <span className="text-lg text-gray-400">/lot</span>
              </div>
              <p className="text-gray-400">
                {tier.volume} lots per month
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CommissionStructure;