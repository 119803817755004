import React from 'react';
import Container from '../ui/Container';
import HeroContent from './sections/HeroContent';
import HeroStats from './sections/HeroStats';
import HeroBackground from './sections/HeroBackground';

const Hero = () => {
  return (
    <div className="relative min-h-screen bg-black overflow-hidden">
      {/* Background Effects */}
      <HeroBackground />
      
      <Container className="relative pt-32 pb-16">
        <div className="text-center max-w-5xl mx-auto px-4">
          {/* Main Content */}
          <HeroContent />

          {/* Stats Grid */}
          <HeroStats />
        </div>
      </Container>
    </div>
  );
};

export default Hero;