import React from 'react';
import { motion } from 'framer-motion';
import { HelpCircle } from 'lucide-react';
import { GradientText } from '../ui/GradientText';

const faqs = [
  {
    question: 'How much can I earn per referral?',
    answer: 'You can earn up to $1,000 per qualified referral. The exact amount depends on your referral\'s trading volume and activity.'
  },
  {
    question: 'When do I get paid?',
    answer: 'Payments are processed monthly for all qualifying referrals and trading activity from the previous month.'
  },
  {
    question: 'Who can join the referral program?',
    answer: 'Any verified HankoTrade user can join our referral program. Simply log in to your account and visit the referral section.'
  },
  {
    question: 'How are referrals tracked?',
    answer: 'Referrals are tracked through your unique referral link. Make sure your referrals use your link when signing up.'
  }
];

const ReferralFAQ = () => {
  return (
    <div className="mb-24">
      <div className="text-center mb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center bg-blue-500/10 text-blue-400 px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
        >
          <HelpCircle className="h-4 w-4 mr-2" />
          Frequently Asked Questions
        </motion.div>

        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
          Common <GradientText>Questions</GradientText>
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {faqs.map((faq, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="group relative"
          >
            <div className="absolute -inset-px rounded-lg bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur opacity-75 group-hover:opacity-100 transition-opacity" />
            
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 group-hover:border-blue-500/50">
              <h3 className="text-xl font-semibold text-white mb-4">
                {faq.question}
              </h3>
              <p className="text-gray-400">
                {faq.answer}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default ReferralFAQ;