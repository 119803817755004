import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../../utils/cn';
import { Loader2 } from 'lucide-react';

const HankoTradeLogo = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="relative group"
    >
      <motion.div
        className="absolute -inset-4 bg-gradient-to-r from-purple-500/20 to-blue-500/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100"
        animate={{
          scale: [1, 1.1, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
          delay: 0.5
        }}
      />
      <div className={cn(
        "relative bg-black rounded-xl p-4",
        "border border-gray-700/50 group-hover:border-purple-500/50",
        "transition-all duration-500",
        "min-h-[48px] md:min-h-[64px]",
        "flex items-center justify-center"
      )}>
        {isLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute inset-0 flex items-center justify-center bg-black rounded-xl"
          >
            <Loader2 className="h-6 w-6 text-blue-500 animate-spin" />
          </motion.div>
        )}
        <motion.img
          src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
          alt="Hanko Trade"
          className={cn(
            "h-8 md:h-12 w-auto",
            isLoading ? 'opacity-0' : 'opacity-100',
            hasError ? 'hidden' : 'block'
          )}
          loading="eager"
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setIsLoading(false);
            setHasError(true);
          }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        />
        {hasError && (
          <div className="text-gray-400 font-medium">
            HankoTrade
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default HankoTradeLogo;