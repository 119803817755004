import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, LogIn, ChevronDown, BookOpen, TrendingUp, Monitor, Laptop, DollarSign } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import Container from '../ui/Container';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const navLinks = [
  { href: '/', label: 'Home' },
  { href: '/#markets', label: 'Markets' },
  { href: '/#trading', label: 'Trading' },
  {
    href: '#',
    label: 'Platform',
    dropdown: [
      { href: '/hankox', label: 'HankoX', icon: Monitor, badge: 'New' },
      { href: '/mtx4', label: 'MTX4', icon: Laptop, badge: 'New' }
    ]
  },
  {
    href: '#',
    label: 'Education',
    dropdown: [
      { href: '/guides', label: 'Trading Guides', icon: BookOpen },
      { href: '/forex-basics', label: 'Forex Basics', icon: TrendingUp, badge: 'New' }
    ]
  },
  { href: '/referral', label: 'Referral Program', badge: '$4/Lot' },
  { 
    href: '#',
    label: 'Bonus',
    dropdown: [
      { href: '/bonus', label: '200% Deposit Bonus', icon: DollarSign, badge: 'New' },
    ]
  }
];

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();

  const handleNavClick = (href: string) => {
    setIsOpen(false);
    
    if (href.startsWith('/#')) {
      if (location.pathname !== '/') {
        window.location.href = href;
        return;
      }
      
      const element = document.querySelector(href.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <nav className="fixed w-full bg-black/80 backdrop-blur-md z-50">
      <Container>
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <img 
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="HankoTrade"
                className="h-8"
              />
            </Link>
            
            {/* Desktop Navigation */}
            <div className="hidden md:block ml-10">
              <div className="flex items-baseline space-x-4">
                {navLinks.map((link) => (
                  <div key={link.label} className="relative group">
                    {link.dropdown ? (
                      <>
                        <button className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors flex items-center gap-1">
                          {link.label}
                          <ChevronDown className="h-4 w-4" />
                        </button>
                        <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-gray-900/95 ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                          <div className="py-1">
                            {link.dropdown.map((item) => (
                              <Link
                                key={item.label}
                                to={item.href}
                                className="flex items-center gap-2 px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-gray-800/50"
                              >
                                <item.icon className="h-4 w-4" />
                                <span>{item.label}</span>
                                {item.badge && (
                                  <span className="ml-auto px-1.5 py-0.5 text-xs font-medium bg-blue-500 text-white rounded-full">
                                    {item.badge}
                                  </span>
                                )}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <Link
                        to={link.href.replace('/#', '/')}
                        onClick={(e) => {
                          if (link.href.startsWith('/#')) {
                            e.preventDefault();
                            handleNavClick(link.href);
                          }
                        }}
                        className="relative text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors"
                      >
                        {link.label}
                        {link.badge && (
                          <span className="absolute -top-1 -right-2 px-1.5 py-0.5 text-xs font-medium bg-blue-500 text-white rounded-full">
                            {link.badge}
                          </span>
                        )}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Desktop CTA Buttons */}
          <div className="hidden md:flex items-center space-x-4">
            <Button
              variant="ghost"
              icon={LogIn}
              href={TRADING_URL}
            >
              Sign In
            </Button>
            <Button href={TRADING_URL}>
              Start Trading
            </Button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden overflow-hidden bg-gray-900/95 backdrop-blur-lg rounded-b-2xl border-t border-gray-800"
            >
              <div className="px-4 pt-2 pb-4 space-y-1">
                {navLinks.map((link) => (
                  <React.Fragment key={link.label}>
                    {link.dropdown ? (
                      <>
                        <div className="px-3 py-2 text-sm font-medium text-gray-400">
                          {link.label}
                        </div>
                        {link.dropdown.map((item) => (
                          <Link
                            key={item.label}
                            to={item.href}
                            className="flex items-center gap-2 px-6 py-2 text-sm text-gray-300 hover:text-white hover:bg-gray-800/50"
                            onClick={() => setIsOpen(false)}
                          >
                            <item.icon className="h-4 w-4" />
                            <span>{item.label}</span>
                            {item.badge && (
                              <span className="ml-auto px-1.5 py-0.5 text-xs font-medium bg-blue-500 text-white rounded-full">
                                {item.badge}
                              </span>
                            )}
                          </Link>
                        ))}
                      </>
                    ) : (
                      <Link
                        to={link.href.replace('/#', '/')}
                        onClick={(e) => {
                          if (link.href.startsWith('/#')) {
                            e.preventDefault();
                            handleNavClick(link.href);
                          }
                        }}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700"
                      >
                        {link.label}
                        {link.badge && (
                          <span className="ml-2 px-1.5 py-0.5 text-xs font-medium bg-blue-500 text-white rounded-full">
                            {link.badge}
                          </span>
                        )}
                      </Link>
                    )}
                  </React.Fragment>
                ))}
                <div className="pt-4 space-y-2">
                  <Button
                    variant="ghost"
                    icon={LogIn}
                    href={TRADING_URL}
                    className="w-full justify-center"
                  >
                    Sign In
                  </Button>
                  <Button 
                    href={TRADING_URL}
                    className="w-full justify-center"
                  >
                    Start Trading
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </Container>
    </nav>
  );
};

export default Navbar;