import React from 'react';
import { motion } from 'framer-motion';
import { PlayCircle, ExternalLink } from 'lucide-react';
import { GradientText } from '../ui/GradientText';

const featuredVideos = [
  {
    id: '1',
    title: 'Getting Started with HankoX Platform',
    thumbnail: 'https://trustedsignals.com/wp-content/uploads/2024/12/Screenshot-2024-12-12-143059.png',
    duration: '10:25',
    views: '1.2K'
  },
  {
    id: '2',
    title: 'Advanced Trading Tools Tutorial',
    thumbnail: 'https://trustedsignals.com/wp-content/uploads/2024/12/Screenshot-2024-12-13-202518.png',
    duration: '15:30',
    views: '856'
  },
  {
    id: '3',
    title: 'Risk Management Strategies',
    thumbnail: 'https://trustedsignals.com/wp-content/uploads/2024/12/Screenshot-2024-12-12-223016.png',
    duration: '12:45',
    views: '2.1K'
  }
];

const YouTubeSection = () => {
  return (
    <div className="mb-24">
      <div className="text-center mb-12">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl md:text-4xl font-bold text-white mb-4"
        >
          Featured <GradientText>Video Tutorials</GradientText>
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto"
        >
          Watch our latest tutorials to master the HankoX platform
        </motion.p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {featuredVideos.map((video, index) => (
          <motion.div
            key={video.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="group relative"
          >
            {/* Ambient glow */}
            <div className="absolute -inset-px rounded-xl bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
            
            {/* Card Content */}
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-700/50 group-hover:border-blue-500/50">
              {/* Thumbnail */}
              <div className="relative aspect-video">
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
                
                {/* Play Button */}
                <motion.div
                  className="absolute inset-0 flex items-center justify-center"
                  whileHover={{ scale: 1.1 }}
                >
                  <div className="w-16 h-16 flex items-center justify-center rounded-full bg-gradient-to-r from-blue-500 to-purple-500 text-white opacity-0 group-hover:opacity-100 transition-all duration-300">
                    <PlayCircle className="h-8 w-8" />
                  </div>
                </motion.div>

                {/* Duration Badge */}
                <div className="absolute bottom-3 right-3 px-3 py-1.5 bg-black/60 text-white text-sm rounded-full backdrop-blur-sm">
                  {video.duration}
                </div>
              </div>

              {/* Content */}
              <div className="p-6">
                <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                  {video.title}
                </h3>
                <div className="flex items-center justify-between text-sm text-gray-400">
                  <span>{video.views} views</span>
                  <motion.a
                    href="https://www.youtube.com/@hankotrade"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-blue-400 hover:text-blue-300 transition-colors"
                    whileHover={{ x: 5 }}
                  >
                    Watch on YouTube
                    <ExternalLink className="h-4 w-4" />
                  </motion.a>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* YouTube Channel Link */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="text-center mt-12"
      >
        <a
          href="https://www.youtube.com/@hankotrade"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg font-medium hover:from-blue-700 hover:to-purple-700 transition-all duration-300"
        >
          View All Tutorials
          <ExternalLink className="h-5 w-5" />
        </a>
      </motion.div>
    </div>
  );
};

export default YouTubeSection;