import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import Button from '../ui/Button';
import { GradientText } from '../ui/GradientText';

const SponsorCTA = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="relative group"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
      
      <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-12 border border-gray-700/50 text-center">
        <h2 className="text-3xl font-bold text-white mb-6">
          Ready to Become a <GradientText>Partner?</GradientText>
        </h2>
        
        <p className="text-gray-400 text-lg mb-8 max-w-2xl mx-auto">
          Join our growing network of successful trading influencers and start earning competitive commissions today.
        </p>
        
        <Button 
          size="lg"
          icon={ArrowRight}
          className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
        >
          Apply Now
        </Button>
      </div>
    </motion.div>
  );
};

export default SponsorCTA;