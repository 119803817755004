import React from 'react';
import Container from '../components/ui/Container';
import EducationHero from '../components/education/EducationHero';
import YouTubeSection from '../components/education/YouTubeSection';
import CourseList from '../components/education/CourseList';

const Guides = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
      {/* Background grid pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.05)_1px,transparent_1px)] bg-[size:32px_32px] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black_70%)]" />

      <Container className="relative pt-32 pb-16">
        {/* Hero Section */}
        <EducationHero />
        
        {/* YouTube Section - Moved to second position */}
        <div className="mb-24">
          <YouTubeSection />
        </div>
        
        {/* Course List */}
        <div className="mb-24">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Upcoming <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600">Courses</span>
            </h2>
            <p className="text-gray-400 text-lg">
              Master HankoX platform with our comprehensive course catalog
            </p>
          </div>
          <CourseList />
        </div>
      </Container>
    </div>
  );
};

export default Guides;