import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, TrendingUp, Users, Shield, Gift, Clock } from 'lucide-react';
import { GradientText } from '../ui/GradientText';

const benefits = [
  {
    icon: DollarSign,
    title: 'High Commissions',
    description: 'Earn $4 per lot traded by your referrals'
  },
  {
    icon: TrendingUp,
    title: 'Performance Bonuses',
    description: 'Additional rewards for high-performing partners'
  },
  {
    icon: Users,
    title: 'Dedicated Support',
    description: '24/7 VIP support for you and your referrals'
  },
  {
    icon: Shield,
    title: 'Reliable Tracking',
    description: 'Advanced system for tracking all referrals'
  },
  {
    icon: Gift,
    title: 'Exclusive Rewards',
    description: 'Special promotions and partner events'
  },
  {
    icon: Clock,
    title: 'Fast Payments',
    description: 'Monthly commission payouts guaranteed'
  }
];

const BenefitsSection = () => {
  return (
    <div className="mb-24">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-white mb-4">
          Partner <GradientText>Benefits</GradientText>
        </h2>
        <p className="text-gray-400 text-lg">
          Join our partner program and enjoy these exclusive benefits
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className="group relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 border border-gray-700/50 hover:border-blue-500/50">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-4">
                <benefit.icon className="h-6 w-6 text-blue-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">{benefit.title}</h3>
              <p className="text-gray-400">{benefit.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default BenefitsSection;