import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, ArrowRight, Shield, Gift, Users } from 'lucide-react';
import Container from '../components/ui/Container';
import Button from '../components/ui/Button';
import { GradientText } from '../components/ui/GradientText';
import { TRADING_URL } from '../utils/constants';
import ChristmasBackground from '../components/bonus/ChristmasBackground';
import ChristmasFeatureCard from '../components/bonus/ChristmasFeatureCard';
import ChristmasStepCard from '../components/bonus/ChristmasStepCard';

const Bonus = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 relative">
      <ChristmasBackground />
      
      <Container className="pt-32 pb-16 relative">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center bg-red-500/10 text-red-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-red-500/20 mb-6"
          >
            <Gift className="h-4 w-4 mr-2" />
            Christmas Special Offer
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-4xl md:text-5xl font-bold text-white mb-6"
          >
            Christmas <GradientText className="from-red-400 via-green-400 to-red-400">200% Bonus</GradientText>
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-3xl mx-auto mb-8"
          >
            🎄 Celebrate Christmas with triple trading power! Deposit $100 and trade with $300. 
            Limited time holiday offer for all traders! 🎁
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-4"
          >
            <Button
              href={TRADING_URL}
              size="lg"
              icon={Gift}
              className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-red-600 to-green-600 hover:from-red-700 hover:to-green-700"
            >
              Claim Your Christmas Bonus
            </Button>
          </motion.div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {[
            {
              icon: Gift,
              title: 'Holiday Bonus',
              description: 'Get up to $75,000 in bonus trading capital this Christmas'
            },
            {
              icon: Shield,
              title: 'Secure Trading',
              description: 'Your funds are protected with bank-grade security'
            },
            {
              icon: Users,
              title: 'For Everyone',
              description: 'Perfect Christmas gift for both manual and EA traders'
            }
          ].map((feature, index) => (
            <ChristmasFeatureCard
              key={index}
              {...feature}
              delay={0.4 + index * 0.1}
            />
          ))}
        </div>

        {/* How It Works Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="relative group mb-16"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-green-500/20 rounded-lg blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
          <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-red-500/20">
            <h2 className="text-2xl font-bold text-white mb-6">Unwrap Your Bonus</h2>
            <div className="space-y-4">
              <ChristmasStepCard
                number={1}
                title="Sign Up & Deposit"
                description="Create an account and make a deposit between $100 and $25,000"
              />
              <ChristmasStepCard
                number={2}
                title="Get Your Bonus"
                description="Receive your 200% Christmas bonus instantly"
              />
              <ChristmasStepCard
                number={3}
                title="Start Trading"
                description="Begin trading with your enhanced holiday capital"
              />
            </div>
          </div>
        </motion.div>

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <h2 className="text-3xl font-bold text-white mb-6">
            Make This Christmas <GradientText className="from-red-400 via-green-400 to-red-400">Special</GradientText>
          </h2>
          <Button
            href={TRADING_URL}
            size="lg"
            icon={Gift}
            className="bg-gradient-to-r from-red-600 to-green-600 hover:from-red-700 hover:to-green-700"
          >
            Claim Your Holiday Bonus
          </Button>
        </motion.div>
      </Container>
    </div>
  );
};

export default Bonus;