import React from 'react';
import Container from '../../ui/Container';
import CopyTradingImage from './CopyTradingImage';
import CopyTradingContent from './CopyTradingContent';

const CopyTradingSection: React.FC = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900 overflow-hidden relative">
      {/* Background grid pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(50,50,50,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(50,50,50,0.05)_1px,transparent_1px)] bg-[size:48px_48px] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black_70%)]" />
      
      <Container className="relative">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <CopyTradingImage />
          <CopyTradingContent />
        </div>
      </Container>
    </section>
  );
};

export default CopyTradingSection;