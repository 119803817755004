import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Gift, Clock, Shield, Users, Globe } from 'lucide-react';
import FadeInWhenVisible from '../animations/FadeInWhenVisible';

const benefits = [
  {
    icon: DollarSign,
    title: 'High Commissions',
    description: 'Earn up to $10 per lot traded by your referred clients'
  },
  {
    icon: Gift,
    title: 'Competitive Rates',
    description: 'Attractive commission rates with no hidden fees'
  },
  {
    icon: Clock,
    title: 'Fast Payments',
    description: 'Regular commission payouts with multiple withdrawal options'
  },
  {
    icon: Shield,
    title: 'Secure Platform',
    description: 'Advanced tracking system for all your referrals'
  },
  {
    icon: Users,
    title: 'Partner Support',
    description: '24/7 dedicated support for all business partners'
  },
  {
    icon: Globe,
    title: 'Global Program',
    description: 'Accept clients from over 100 countries worldwide'
  }
];

const ReferralBenefits = () => {
  return (
    <div className="mb-24">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <FadeInWhenVisible key={index} delay={0.1 * index}>
            <motion.div
              whileHover={{ y: -5 }}
              className="group relative"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity duration-300" />
              
              <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 hover:bg-gray-800/70 transition-all duration-300 border border-gray-700/50 hover:border-blue-500/50">
                <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6 group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                  <benefit.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
                </div>
                
                <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                  {benefit.title}
                </h3>
                
                <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                  {benefit.description}
                </p>
              </div>
            </motion.div>
          </FadeInWhenVisible>
        ))}
      </div>
    </div>
  );
};

export default ReferralBenefits;