import React from 'react';

interface VideoIframeProps {
  embedUrl: string;
}

const VideoIframe: React.FC<VideoIframeProps> = ({ embedUrl }) => {
  return (
    <iframe
      src={embedUrl}
      className="absolute inset-0 w-full h-full"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      loading="lazy"
    />
  );
};

export default VideoIframe;