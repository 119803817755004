import React from 'react';
import { motion } from 'framer-motion';
import { Download } from 'lucide-react';
import { GradientText } from '../ui/GradientText';
import StepCard from './steps/StepCard';
import { steps } from './steps/stepsData';

const MTX4Steps = () => {
  return (
    <div className="py-24 border-t border-gray-800/50">
      <div className="text-center mb-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center bg-blue-500/10 text-blue-400 px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
        >
          <Download className="h-4 w-4 mr-2" />
          Get Started with MTX4
        </motion.div>

        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-3xl md:text-4xl font-bold text-white mb-4"
        >
          Start Your Trading Journey with{' '}
          <GradientText>MTX4</GradientText>
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto"
        >
          Follow these simple steps to begin your trading journey with our enhanced MTX4 platform
        </motion.p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {steps.map((step, index) => (
          <StepCard key={index} {...step} index={index} />
        ))}
      </div>
    </div>
  );
};

export default MTX4Steps;