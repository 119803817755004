import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Gift, Shield } from 'lucide-react';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const StickyCTA = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 500);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          className="fixed bottom-0 left-0 right-0 z-50 bg-gray-900/95 backdrop-blur-lg border-t border-gray-800"
        >
          <div className="container mx-auto px-4 py-4 flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              <Gift className="h-5 w-5 text-blue-400" />
              <span className="text-white font-medium">
                Get 200% Bonus on Your First Deposit
              </span>
            </div>
            <div className="flex gap-4">
              <Button
                href={TRADING_URL}
                size="sm"
                icon={Gift}
                className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
              >
                Start Trading
              </Button>
              <Button
                href={TRADING_URL}
                variant="secondary"
                size="sm"
                icon={Shield}
              >
                Demo Account
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default StickyCTA;