import React from 'react';
import Container from '../components/ui/Container';
import ReferralHero from '../components/referral/ReferralHero';
import ReferralBenefits from '../components/referral/ReferralBenefits';
import MoneyFlow from '../components/referral/flow/MoneyFlow';
import ReferralCommissions from '../components/referral/ReferralCommissions';
import ReferralFAQ from '../components/referral/ReferralFAQ';
import ReferralCTA from '../components/referral/ReferralCTA';

const Referral = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
      <Container className="pt-32 pb-16">
        {/* Hero Section */}
        <ReferralHero />
        
        {/* Money Flow Visualization */}
        <div className="mb-24">
          <MoneyFlow />
        </div>
        
        {/* Commission Structure with Calculator */}
        <ReferralCommissions />
        
        {/* Benefits Section */}
        <ReferralBenefits />
        
        {/* FAQ Section */}
        <ReferralFAQ />
        
        {/* Call to Action */}
        <ReferralCTA />
      </Container>
    </div>
  );
};

export default Referral;