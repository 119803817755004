import React from 'react';
import { motion } from 'framer-motion';
import { Globe, ArrowLeftRight, TrendingUp } from 'lucide-react';
import { GradientText } from '../../ui/GradientText';

const CurrencyPairs = () => {
  return (
    <section id="currency-pairs" className="scroll-mt-20">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
          Understanding <GradientText>Currency Pairs</GradientText>
        </h2>
        <p className="text-gray-400 text-lg">
          Learn how currency pairs work and their role in forex trading
        </p>
      </motion.div>

      {/* Currency Pair Structure */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="relative group mb-12"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
        <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50">
          <h3 className="text-xl font-semibold text-white mb-6">Currency Pair Structure</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <div className="flex items-center justify-center p-6 bg-gray-900/50 rounded-lg mb-4">
                <div className="text-2xl font-mono text-white">
                  <span className="text-blue-400">EUR</span>
                  <span className="text-gray-400">/</span>
                  <span className="text-green-400">USD</span>
                </div>
              </div>
              <div className="space-y-3">
                <p className="text-gray-300">
                  <span className="text-blue-400 font-medium">Base Currency (EUR):</span> The first currency in the pair
                </p>
                <p className="text-gray-300">
                  <span className="text-green-400 font-medium">Quote Currency (USD):</span> The second currency in the pair
                </p>
                <p className="text-gray-300">
                  <span className="text-gray-400 font-medium">/</span> Separator between base and quote currencies
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <h4 className="text-lg font-medium text-white">Reading the Price:</h4>
              <p className="text-gray-300">
                If EUR/USD = 1.2000, it means:
              </p>
              <ul className="space-y-3">
                <li className="flex items-start gap-3 text-gray-300">
                  <ArrowLeftRight className="h-5 w-5 text-blue-400 mt-0.5" />
                  1 EUR = 1.20 USD
                </li>
                <li className="flex items-start gap-3 text-gray-300">
                  <TrendingUp className="h-5 w-5 text-blue-400 mt-0.5" />
                  If price rises to 1.2100: Euro strengthens against USD
                </li>
                <li className="flex items-start gap-3 text-gray-300">
                  <TrendingUp className="h-5 w-5 text-red-400 mt-0.5 rotate-180" />
                  If price falls to 1.1900: Euro weakens against USD
                </li>
              </ul>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Types of Currency Pairs */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {[
          {
            title: 'Major Pairs',
            description: 'Most traded currency pairs involving USD',
            examples: ['EUR/USD', 'GBP/USD', 'USD/JPY', 'USD/CHF']
          },
          {
            title: 'Minor Pairs',
            description: 'Pairs not including USD but major currencies',
            examples: ['EUR/GBP', 'EUR/JPY', 'GBP/JPY', 'EUR/CHF']
          },
          {
            title: 'Exotic Pairs',
            description: 'Pairs including one major and one emerging market currency',
            examples: ['USD/TRY', 'EUR/PLN', 'USD/ZAR', 'USD/MXN']
          }
        ].map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.2 }}
            className="group relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 hover:border-blue-500/50 h-full">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6">
                <Globe className="h-6 w-6 text-blue-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-4">{item.title}</h3>
              <p className="text-gray-400 mb-4">{item.description}</p>
              <div className="flex flex-wrap gap-2">
                {item.examples.map((example, i) => (
                  <span key={i} className="px-3 py-1 bg-blue-500/10 text-blue-400 rounded-full text-sm border border-blue-500/20">
                    {example}
                  </span>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default CurrencyPairs;