import React from 'react';
import { motion } from 'framer-motion';

const HeroDescription = () => {
  return (
    <motion.p
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      className="text-gray-300 text-lg md:text-xl max-w-3xl mx-auto mb-12"
    >
      Start trading with our custom-branded MTX4 platform, powered by the reliable MetaTrader 4 technology. 
      Experience professional-grade trading tools and features designed specifically for MagnoFX users.
    </motion.p>
  );
};

export default HeroDescription;