import React from 'react';
import HeroTitle from './hero/HeroTitle';
import HeroDescription from './hero/HeroDescription';
import HeroActions from './hero/HeroActions';
import PartnershipLogos from './PartnershipLogos';

const MTX4Hero = () => {
  return (
    <div className="relative">
      {/* Partnership Logos */}
      <PartnershipLogos />

      <div className="text-center max-w-5xl mx-auto px-4">
        {/* Main Content */}
        <HeroTitle />
        <HeroDescription />
        <HeroActions />
      </div>
    </div>
  );
};

export default MTX4Hero;