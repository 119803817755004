import React from 'react';
import { motion } from 'framer-motion';
import { UserPlus, CheckCircle, MessageSquare, ArrowRight } from 'lucide-react';
import { GradientText } from '../ui/GradientText';

const steps = [
  {
    icon: UserPlus,
    title: 'Apply',
    description: 'Fill out our partner application form'
  },
  {
    icon: CheckCircle,
    title: 'Get Approved',
    description: 'Quick review process for qualified partners'
  },
  {
    icon: MessageSquare,
    title: 'Start Promoting',
    description: 'Begin sharing your unique referral links'
  }
];

const ApplicationProcess = () => {
  return (
    <div className="mb-24">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-white mb-4">
          How to <GradientText>Get Started</GradientText>
        </h2>
        <p className="text-gray-400 text-lg">
          Simple steps to become a HankoX partner
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {steps.map((step, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className="group relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 hover:border-blue-500/50">
              <div className="flex items-center mb-6">
                <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20">
                  <step.icon className="h-6 w-6 text-blue-400" />
                </div>
                <div className="ml-4 text-2xl font-bold text-white">
                  Step {index + 1}
                </div>
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">{step.title}</h3>
              <p className="text-gray-400">{step.description}</p>

              {index < steps.length - 1 && (
                <ArrowRight className="absolute -right-4 top-1/2 transform -translate-y-1/2 text-blue-500/50 h-8 w-8 hidden md:block" />
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ApplicationProcess;