import React from 'react';
import Container from '../components/ui/Container';
import FadeInWhenVisible from '../components/animations/FadeInWhenVisible';
import MTX4Hero from '../components/mtx4/MTX4Hero';
import MTX4Steps from '../components/mtx4/MTX4Steps';
import MTX4Features from '../components/mtx4/MTX4Features';
import MTX4Support from '../components/mtx4/MTX4Support';
import MTX4Notification from '../components/mtx4/notification/MTX4Notification';

const MTX4 = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
      <Container className="pt-24 md:pt-28 pb-16"> {/* Reduced top padding */}
        <FadeInWhenVisible>
          {/* Notification about MTX4 being MT4 */}
          <MTX4Notification />
          
          {/* Hero Section */}
          <MTX4Hero />
          
          {/* Trading Journey Section */}
          <MTX4Steps />
          
          {/* Features Section */}
          <MTX4Features />
          
          {/* Support Section */}
          <MTX4Support />
        </FadeInWhenVisible>
      </Container>
    </div>
  );
};

export default MTX4;