import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { getYouTubeVideoId, getYouTubeEmbedUrl } from '../../../utils/youtube';
import VideoBackdrop from './VideoBackdrop';
import VideoContainer from './VideoContainer';
import { useEscapeKey } from '../../../hooks/useEscapeKey';
import { useScrollLock } from '../../../hooks/useScrollLock';

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, videoUrl }) => {
  useEscapeKey(onClose);
  useScrollLock(isOpen);

  const videoId = getYouTubeVideoId(videoUrl);
  const embedUrl = videoId ? getYouTubeEmbedUrl(videoId) : null;

  if (!embedUrl) {
    console.error('Invalid YouTube URL');
    return null;
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center p-4 md:p-8">
          <VideoBackdrop onClose={onClose} />
          <VideoContainer onClose={onClose} embedUrl={embedUrl} />
        </div>
      )}
    </AnimatePresence>
  );
};

export default VideoModal;