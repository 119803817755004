import React from 'react';
import { motion } from 'framer-motion';
import { ExternalLink, Instagram, Youtube, Twitter } from 'lucide-react';
import { Link } from 'react-router-dom';
import { cn } from '../../utils/cn';

interface InfluencerCardProps {
  name: string;
  handle: string;
  bio: string;
  photo: string;
  metrics: {
    followers: string;
    experience: string;
    signals: string;
  };
  social: {
    instagram?: string;
    youtube?: string;
    twitter?: string;
  };
  referralLink: string;
}

const InfluencerCard: React.FC<InfluencerCardProps> = ({
  name,
  handle,
  bio,
  photo,
  metrics,
  social,
  referralLink
}) => {
  return (
    <motion.div
      whileHover={{ y: -5 }}
      className="group relative"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
      
      <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg overflow-hidden border border-gray-700/50 hover:border-blue-500/50">
        {/* Header with photo */}
        <div className="relative h-48 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/80" />
          <img
            src={photo}
            alt={name}
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-4 left-4 right-4">
            <h3 className="text-2xl font-bold text-white mb-1">{name}</h3>
            <p className="text-blue-400">@{handle}</p>
          </div>
        </div>

        <div className="p-6">
          <p className="text-gray-400 mb-6">{bio}</p>

          {/* Metrics */}
          <div className="grid grid-cols-3 gap-4 mb-6">
            {Object.entries(metrics).map(([key, value]) => (
              <div key={key} className="text-center">
                <div className="text-lg font-bold text-white">{value}</div>
                <div className="text-sm text-gray-400 capitalize">{key}</div>
              </div>
            ))}
          </div>

          {/* Social Links */}
          <div className="flex items-center gap-4 mb-6">
            {Object.entries(social).map(([platform, url]) => {
              const Icon = {
                instagram: Instagram,
                youtube: Youtube,
                twitter: Twitter
              }[platform];
              
              return Icon && url ? (
                <a
                  key={platform}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full hover:bg-blue-500/10 text-gray-400 hover:text-blue-400 transition-colors"
                >
                  <Icon className="h-5 w-5" />
                </a>
              ) : null;
            })}
          </div>

          {/* CTA Button */}
          <Link
            to={`/${handle.toLowerCase()}`}
            className={cn(
              "flex items-center justify-center gap-2 w-full",
              "px-4 py-3 rounded-lg font-medium",
              "bg-gradient-to-r from-blue-600 to-purple-600",
              "hover:from-blue-700 hover:to-purple-700",
              "text-white transition-all duration-300"
            )}
          >
            Trade with {handle}
            <ExternalLink className="h-5 w-5" />
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default InfluencerCard;