import React from 'react';
import { LineChart, BarChart2, TrendingUp, Settings, Shield, BookOpen } from 'lucide-react';
import CourseCard from './CourseCard';

const courses = [
  {
    icon: LineChart,
    title: 'HankoX Platform Basics',
    description: 'Master the fundamentals of HankoX trading platform and its key features',
    lessons: 12,
    duration: '2 hours',
    gradient: 'from-blue-500/20 to-cyan-500/20'
  },
  {
    icon: BarChart2,
    title: 'Advanced Trading Tools',
    description: 'Deep dive into professional trading tools and technical analysis',
    lessons: 15,
    duration: '3 hours',
    gradient: 'from-purple-500/20 to-pink-500/20'
  },
  {
    icon: TrendingUp,
    title: 'Trading Strategies',
    description: 'Learn proven trading strategies and risk management techniques',
    lessons: 20,
    duration: '4 hours',
    gradient: 'from-green-500/20 to-emerald-500/20'
  },
  {
    icon: Settings,
    title: 'Platform Customization',
    description: 'Customize your trading workspace for maximum efficiency',
    lessons: 8,
    duration: '1.5 hours',
    gradient: 'from-amber-500/20 to-orange-500/20'
  },
  {
    icon: Shield,
    title: 'Risk Management',
    description: 'Master essential risk management principles and tools',
    lessons: 10,
    duration: '2 hours',
    gradient: 'from-red-500/20 to-rose-500/20'
  },
  {
    icon: BookOpen,
    title: 'Market Analysis',
    description: 'Learn to analyze markets and identify trading opportunities',
    lessons: 18,
    duration: '3.5 hours',
    gradient: 'from-indigo-500/20 to-blue-500/20'
  }
];

const CourseList = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {courses.map((course, index) => (
        <CourseCard
          key={index}
          {...course}
        />
      ))}
    </div>
  );
};

export default CourseList;