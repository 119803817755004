import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';

interface FeatureIconProps {
  icon: LucideIcon;
  gradient: string;
}

const iconVariants = {
  hidden: { scale: 0, rotate: -180 },
  visible: {
    scale: 1,
    rotate: 0,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 20
    }
  },
  hover: {
    scale: 1.1,
    rotate: 5,
    transition: {
      duration: 0.3
    }
  }
};

export const FeatureIcon: React.FC<FeatureIconProps> = ({ icon: Icon, gradient }) => {
  return (
    <motion.div
      variants={iconVariants}
      whileHover="hover"
      className={`flex items-center justify-center w-14 h-14 rounded-lg bg-gradient-to-br ${gradient} mb-6`}
    >
      <Icon className="h-7 w-7 text-white" />
    </motion.div>
  );
};