import React from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, DollarSign, Globe, Clock } from 'lucide-react';
import { GradientText } from '../../ui/GradientText';

const ForexBasics = () => {
  return (
    <section id="forex-basics" className="scroll-mt-20">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
          What is <GradientText>Forex Trading?</GradientText>
        </h2>
        <p className="text-gray-400 text-lg">
          Foreign Exchange (Forex) trading is the buying and selling of currencies on the global market
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          {
            icon: Globe,
            title: 'Global Market',
            description: 'The forex market is the largest financial market in the world, with over $6 trillion traded daily'
          },
          {
            icon: Clock,
            title: '24/5 Trading',
            description: 'Trade currencies around the clock from Sunday evening to Friday afternoon'
          },
          {
            icon: DollarSign,
            title: 'Currency Exchange',
            description: 'Profit from the changing values between different currencies'
          }
        ].map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.2 }}
            className="group relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 hover:border-blue-500/50">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6">
                <item.icon className="h-6 w-6 text-blue-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-4">{item.title}</h3>
              <p className="text-gray-400">{item.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default ForexBasics;