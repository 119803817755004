import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import Button from '../ui/Button';
import { GradientText } from '../ui/GradientText';

const ReferralCTA = () => {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-xl opacity-50" />
      
      <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-12 border border-gray-700/50 text-center">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl md:text-4xl font-bold text-white mb-6"
        >
          Ready to Start <GradientText>Earning?</GradientText>
        </motion.h2>
        
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-gray-400 text-lg mb-8 max-w-2xl mx-auto"
        >
          Join our referral program today and start earning rewards for sharing HankoTrade with your network.
        </motion.p>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Button
            size="lg"
            icon={ArrowRight}
            className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
          >
            Join Referral Program
          </Button>
        </motion.div>
      </div>
    </div>
  );
}

export default ReferralCTA;