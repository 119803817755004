import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, Zap, Shield } from 'lucide-react';
import { GradientText } from '../../ui/GradientText';
import Button from '../../ui/Button';
import { TRADING_URL } from '../../../utils/constants';

const features = [
  {
    icon: LineChart,
    title: 'Advanced Analysis',
    description: 'Professional-grade charting tools and technical indicators for precise market analysis',
    gradient: 'from-blue-500/20 to-cyan-500/20'
  },
  {
    icon: Zap,
    title: 'Fast Execution',
    description: 'Lightning-fast trade execution with minimal slippage for optimal trading performance',
    gradient: 'from-purple-500/20 to-pink-500/20'
  },
  {
    icon: Shield,
    title: 'Secure Trading',
    description: 'Bank-grade security measures to protect your funds and trading activities',
    gradient: 'from-green-500/20 to-emerald-500/20'
  }
];

const PlatformSection = () => {
  return (
    <div className="mb-24">
      <div className="text-center mb-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl md:text-4xl font-bold text-white mb-4"
        >
          Professional <GradientText>Trading Platform</GradientText>
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto"
        >
          Experience advanced trading tools and features designed for both beginners and professionals
        </motion.p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 * index }}
            whileHover={{ y: -5 }}
            className="relative group"
          >
            <div className={`absolute inset-0 bg-gradient-to-r ${feature.gradient} rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity`} />
            <div className="relative h-full bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50">
              {/* Added Icon */}
              <div className="flex items-center justify-center w-14 h-14 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6 group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                <feature.icon className="h-7 w-7 text-blue-400 group-hover:text-blue-300 transition-colors" />
              </div>

              <h3 className="text-xl font-semibold text-white mb-4 group-hover:text-blue-400 transition-colors">
                {feature.title}
              </h3>
              <p className="text-gray-400 leading-relaxed group-hover:text-gray-300 transition-colors">
                {feature.description}
              </p>
              
              {/* Animated border effect */}
              <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-transparent via-blue-500/10 to-transparent opacity-0 group-hover:opacity-100 -translate-x-full group-hover:translate-x-full transition-all duration-1000" />
            </div>
          </motion.div>
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="text-center"
      >
        <Button
          href={TRADING_URL}
          size="lg"
          className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
        >
          Start Trading Now
        </Button>
      </motion.div>
    </div>
  );
};

export default PlatformSection;