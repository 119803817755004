import React from 'react';
import { motion } from 'framer-motion';

interface ChristmasStepCardProps {
  number: number;
  title: string;
  description: string;
}

const ChristmasStepCard: React.FC<ChristmasStepCardProps> = ({
  number,
  title,
  description
}) => {
  return (
    <div className="flex items-start gap-3">
      <motion.div
        whileHover={{ scale: 1.1, rotate: [0, -10, 10, 0] }}
        className="flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-br from-red-500/20 to-green-500/20 flex items-center justify-center text-red-400 font-bold"
      >
        {number}
      </motion.div>
      <div>
        <h3 className="text-white font-medium mb-1">{title}</h3>
        <p className="text-gray-400">{description}</p>
      </div>
    </div>
  );
};

export default ChristmasStepCard;