import React from 'react';
import { motion } from 'framer-motion';
import { Users, Star, TrendingUp, DollarSign } from 'lucide-react';
import { GradientText } from '../ui/GradientText';
import Button from '../ui/Button';

const SponsorHero = () => {
  return (
    <div className="text-center mb-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="inline-flex items-center bg-blue-500/10 text-blue-400 px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
      >
        <Users className="h-4 w-4 mr-2" />
        Influencer Partner Program
      </motion.div>

      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="text-4xl md:text-6xl font-bold text-white mb-6"
      >
        Become a <GradientText>HankoX Partner</GradientText>
      </motion.h1>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="text-gray-400 text-lg max-w-3xl mx-auto mb-12"
      >
        Join our exclusive network of trading influencers and earn competitive commissions 
        while helping your audience succeed in their trading journey.
      </motion.p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
        {[
          { icon: Star, value: '100+', label: 'Active Partners' },
          { icon: TrendingUp, value: '$1M+', label: 'Monthly Commissions' },
          { icon: DollarSign, value: '$4/Lot', label: 'Commission Rate' }
        ].map((stat, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 + index * 0.1 }}
            className="relative group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 border border-gray-700/50">
              <stat.icon className="h-8 w-8 text-blue-400 mb-4 mx-auto" />
              <div className="text-3xl font-bold text-white mb-2">{stat.value}</div>
              <div className="text-gray-400">{stat.label}</div>
            </div>
          </motion.div>
        ))}
      </div>

      <Button
        size="lg"
        className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
      >
        Apply Now
      </Button>
    </div>
  );
};

export default SponsorHero;