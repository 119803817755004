import React from 'react';
import { motion } from 'framer-motion';
import CurrencyPairRow from './CurrencyPairRow';

interface CurrencyPair {
  pair: string;
  spread: number;
}

interface CurrencyPairsTableProps {
  pairs: CurrencyPair[];
}

const CurrencyPairsTable: React.FC<CurrencyPairsTableProps> = ({ pairs }) => {
  return (
    <div className="overflow-x-auto -mx-4 sm:mx-0">
      <div className="min-w-full inline-block align-middle">
        <div className="overflow-hidden">
          <table className="min-w-full">
            <thead>
              <tr className="text-left border-b border-gray-700">
                <th className="py-4 px-3 sm:px-6 text-gray-400 font-medium text-sm sm:text-base">Symbol</th>
                <th className="py-4 px-3 sm:px-6 text-gray-400 font-medium text-sm sm:text-base">Spread From</th>
              </tr>
            </thead>
            <tbody>
              {pairs.map((pair, index) => (
                <CurrencyPairRow
                  key={pair.pair}
                  pair={pair.pair}
                  spread={pair.spread}
                  index={index}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CurrencyPairsTable;