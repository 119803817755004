import React from 'react';
import { motion } from 'framer-motion';
import { Users, DollarSign, TrendingUp } from 'lucide-react';
import { cn } from '../../../utils/cn';

const steps = [
  { 
    icon: Users, 
    title: 'Refer Clients', 
    description: 'Share your unique referral link with potential clients',
    color: 'from-blue-500/20 to-cyan-500/20',
    gradient: 'from-blue-500 to-cyan-500'
  },
  { 
    icon: TrendingUp, 
    title: 'Clients Trade', 
    description: 'Your clients start trading on HankoX platform',
    color: 'from-purple-500/20 to-pink-500/20',
    gradient: 'from-purple-500 to-pink-500'
  },
  { 
    icon: DollarSign, 
    title: 'Earn $4/Lot', 
    description: 'Receive commission for every lot traded',
    color: 'from-emerald-500/20 to-green-500/20',
    gradient: 'from-emerald-500 to-green-500'
  }
];

const MoneyFlow = () => {
  return (
    <div className="relative py-12">
      {/* Center Logo with Enhanced Animations */}
      <div className="flex justify-center mb-24">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          className="relative w-48 h-48 md:w-56 md:h-56"
        >
          {/* Enhanced Logo Glow */}
          <motion.div
            className="absolute -inset-12 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-emerald-500/20 rounded-full blur-3xl"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />

          {/* Rotating Ring */}
          <motion.div
            className="absolute -inset-6 border-2 border-blue-500/20 rounded-full"
            animate={{ rotate: 360 }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear"
            }}
          />

          {/* Logo Container */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="relative w-32 h-32 md:w-40 md:h-40 bg-gray-800/50 backdrop-blur-sm rounded-full p-6 border border-gray-700/50">
              <motion.img
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="HankoX"
                className="w-full h-full object-contain"
                animate={{
                  scale: [1, 1.05, 1],
                  rotate: [0, 5, 0],
                }}
                transition={{
                  duration: 6,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
            </div>
          </div>

          {/* Orbiting Elements */}
          {[...Array(8)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-8 h-8 -translate-x-1/2 -translate-y-1/2"
              style={{
                top: '50%',
                left: '50%',
              }}
              animate={{
                rotate: [i * 45, i * 45 + 360],
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
                ease: "linear"
              }}
            >
              <motion.div
                className="absolute w-3 h-3 bg-blue-500 rounded-full"
                style={{
                  left: '100px',
                }}
                animate={{
                  scale: [1, 1.5, 1],
                  opacity: [0.3, 0.7, 0.3],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                  delay: i * 0.2
                }}
              />
            </motion.div>
          ))}

          {/* Floating Dollar Signs */}
          {[...Array(12)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute left-1/2 top-1/2"
              animate={{
                x: [
                  Math.cos(i * 30 * Math.PI / 180) * 100,
                  Math.cos((i * 30 + 180) * Math.PI / 180) * 100
                ],
                y: [
                  Math.sin(i * 30 * Math.PI / 180) * 100,
                  Math.sin((i * 30 + 180) * Math.PI / 180) * 100
                ],
                opacity: [0, 1, 0],
                scale: [0.5, 1, 0.5],
              }}
              transition={{
                duration: 3 + Math.random() * 2,
                repeat: Infinity,
                delay: i * 0.2,
                ease: "easeInOut"
              }}
            >
              <DollarSign className="h-4 w-4 text-green-400" />
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Steps */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto px-4">
        {steps.map((step, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
            className="relative group"
          >
            <div className={cn(
              "absolute -inset-px rounded-xl bg-gradient-to-r blur-xl opacity-50 group-hover:opacity-100 transition-opacity",
              step.color
            )} />
            
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 group-hover:border-blue-500/50 text-center h-full">
              <div className={cn(
                "flex items-center justify-center w-20 h-20 mx-auto mb-6 rounded-xl",
                "bg-gradient-to-br",
                step.gradient,
                "bg-opacity-20"
              )}>
                <step.icon className="h-10 w-10 text-white" />
              </div>
              
              <h3 className="text-xl font-bold text-white mb-4">{step.title}</h3>
              <p className="text-gray-300">{step.description}</p>

              {index < steps.length - 1 && (
                <div className="absolute top-1/2 -right-4 w-8 hidden md:block">
                  <motion.div
                    className="h-0.5 bg-gradient-to-r from-blue-500 to-purple-500"
                    initial={{ scaleX: 0 }}
                    animate={{ scaleX: 1 }}
                    transition={{ duration: 1, delay: index * 0.3 }}
                  >
                    <motion.div
                      className="absolute right-0 -top-1 w-2 h-2 bg-purple-500 rounded-full"
                      animate={{
                        x: [0, 32, 0],
                        scale: [1, 1.2, 1],
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "linear",
                        delay: index * 0.5
                      }}
                    />
                  </motion.div>
                </div>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default MoneyFlow;