import React from 'react';
import { motion } from 'framer-motion';
import { Monitor, ChevronRight } from 'lucide-react';
import Button from '../ui/Button';
import { GradientText } from '../ui/GradientText';

const PlatformHero = () => {
  return (
    <div className="grid lg:grid-cols-2 gap-12 items-center mb-24">
      <div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
        >
          <Monitor className="h-4 w-4 mr-2" />
          Next-Gen Trading Platform
        </motion.div>

        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-4xl md:text-6xl font-bold text-white mb-6 leading-tight"
        >
          Trade Smarter with{' '}
          <GradientText>HankoX Platform</GradientText>
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-gray-300 text-lg mb-8 leading-relaxed"
        >
          Experience professional-grade trading with our cutting-edge platform. Advanced charting, real-time data, and powerful tools designed for both novice and experienced traders.
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="flex flex-col sm:flex-row gap-4"
        >
          <Button
            size="lg"
            icon={ChevronRight}
            className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
          >
            Start Trading Now
          </Button>
          <Button
            variant="secondary"
            size="lg"
          >
            View Features
          </Button>
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4 }}
        className="relative"
      >
        <div className="relative rounded-2xl overflow-hidden shadow-2xl">
          <img
            src="https://login.hankotrade.com/static/img/act-img-new-2.e9bdb18.png"
            alt="HankoX Trading Platform"
            className="w-full h-auto rounded-2xl transform hover:scale-105 transition-transform duration-500"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 mix-blend-overlay" />
        </div>

        {/* Decorative elements */}
        <div className="absolute -inset-4 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-3xl opacity-50 rounded-full" />
        <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-blue-500/20 to-purple-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
        <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-purple-500/20 to-blue-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
      </motion.div>
    </div>
  );
}

export default PlatformHero;