import { LineChart, Bot, Globe, Shield, Zap, Lock } from 'lucide-react';

export const features = [
  {
    icon: LineChart,
    title: 'MT4 Compatibility',
    description: 'Full compatibility with Expert Advisors (EA) and all MT4 features',
    gradient: 'from-blue-500/20 to-cyan-500/20'
  },
  {
    icon: Bot,
    title: 'Expert Advisors',
    description: 'Deploy and run your automated trading strategies seamlessly',
    gradient: 'from-purple-500/20 to-pink-500/20'
  },
  {
    icon: Globe,
    title: 'Cross-Platform',
    description: 'Available on Windows, Mac, mobile devices, and web browsers',
    gradient: 'from-green-500/20 to-emerald-500/20'
  },
  {
    icon: Shield,
    title: 'Secure Transfers',
    description: 'Internal transfers between Hankotrade and MagnoFx via support',
    gradient: 'from-orange-500/20 to-red-500/20'
  },
  {
    icon: Zap,
    title: 'Fast Execution',
    description: 'Lightning-fast trade execution with minimal latency',
    gradient: 'from-yellow-500/20 to-orange-500/20'
  },
  {
    icon: Lock,
    title: 'Enhanced Security',
    description: 'Bank-grade encryption for all your trading activities',
    gradient: 'from-indigo-500/20 to-blue-500/20'
  }
] as const;