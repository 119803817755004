import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';
import { cn } from '../../utils/cn';

interface CourseCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  lessons: number;
  duration: string;
  comingSoon?: boolean;
  gradient?: string;
}

const CourseCard: React.FC<CourseCardProps> = ({
  icon: Icon,
  title,
  description,
  lessons,
  duration,
  comingSoon = true,
  gradient = 'from-blue-500/20 to-purple-500/20'
}) => {
  return (
    <motion.div
      whileHover={{ y: -5 }}
      className="group relative"
    >
      <div className={cn(
        "absolute inset-0 rounded-xl bg-gradient-to-r blur-xl opacity-50 group-hover:opacity-100 transition-opacity",
        gradient
      )} />
      
      <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 group-hover:border-blue-500/50">
        {/* Icon */}
        <div className={cn(
          "w-14 h-14 rounded-lg bg-gradient-to-br mb-6 flex items-center justify-center",
          gradient
        )}>
          <Icon className="h-7 w-7 text-white" />
        </div>

        {/* Content */}
        <h3 className="text-xl font-semibold text-white mb-3 group-hover:text-blue-400 transition-colors">
          {title}
        </h3>
        
        <p className="text-gray-400 mb-6 group-hover:text-gray-300 transition-colors">
          {description}
        </p>

        {/* Course Info */}
        <div className="flex items-center justify-between text-sm">
          <div className="text-gray-400">
            <span className="text-blue-400">{lessons}</span> Lessons
          </div>
          <div className="text-gray-400">
            <span className="text-blue-400">{duration}</span>
          </div>
        </div>

        {/* Coming Soon Badge */}
        {comingSoon && (
          <div className="absolute top-4 right-4">
            <div className="px-3 py-1 bg-blue-500/10 rounded-full text-blue-400 text-sm border border-blue-500/20">
              Coming Soon
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default CourseCard;