import React from 'react';
import { motion } from 'framer-motion';
import { Gift, Star, Shield, Award, TrendingUp } from 'lucide-react';
import Button from '../../ui/Button';
import { GradientText } from '../../ui/GradientText';
import HeroLogo from '../elements/HeroLogo';
import HeroBadges from './HeroBadges';

const HeroContent = () => {
  return (
    <>
      {/* Logo */}
      <HeroLogo />

      {/* Holiday Bonus Badge */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="inline-flex items-center bg-gradient-to-r from-blue-500/10 to-purple-500/10 text-blue-400 px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
      >
        <Gift className="h-4 w-4 mr-2" />
        ✨ Holiday Special: 200% Deposit Bonus
      </motion.div>

      {/* Best Broker Badge */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="inline-flex items-center bg-blue-500/10 text-blue-400 px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6 ml-4"
      >
        <Award className="h-4 w-4 mr-2" />
        #1 Rated Forex Broker 2023
      </motion.div>

      {/* Feature Badges */}
      <HeroBadges />

      {/* Main Heading */}
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-6 leading-tight"
      >
        Triple Your <GradientText className="from-blue-400 via-purple-400 to-blue-400 animate-gradient">Trading Power</GradientText>{' '}
        This Holiday
      </motion.h1>

      {/* Description */}
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="text-lg text-gray-400 mb-8 max-w-2xl mx-auto px-4"
      >
        Join the world's leading forex broker and get a 200% holiday bonus. 
        Deposit $100, trade with $300! Limited time offer for both manual and EA traders.
      </motion.p>

      {/* Broker Features */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="flex flex-wrap justify-center gap-4 mb-8"
      >
        {[
          { icon: Star, text: "Ultra-Low Spreads from 0.0 pips" },
          { icon: Shield, text: "Bank-Grade Security" },
          { icon: TrendingUp, text: "200% Holiday Bonus" }
        ].map((feature, index) => (
          <div
            key={index}
            className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-gray-800/50 to-gray-900/50 rounded-full backdrop-blur-sm border border-gray-700/50"
          >
            <feature.icon className="h-4 w-4 text-blue-400" />
            <span className="text-sm text-gray-300">{feature.text}</span>
          </div>
        ))}
      </motion.div>

      {/* CTA Buttons */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-16 px-4"
      >
        <Button
          size="lg"
          icon={Gift}
          className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
        >
          Claim 200% Bonus Now
        </Button>
        <Button
          variant="secondary"
          size="lg"
          className="w-full sm:w-auto min-w-[200px] bg-gray-900 hover:bg-gray-800"
        >
          Open Demo Account
        </Button>
      </motion.div>
    </>
  );
};

export default HeroContent;