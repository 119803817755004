import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Bitcoin, LineChart } from 'lucide-react';

const FloatingElements = () => {
  return (
    <div className="absolute inset-0 pointer-events-none overflow-hidden">
      {/* Floating Icons */}
      {[DollarSign, Bitcoin, LineChart].map((Icon, index) => (
        <motion.div
          key={index}
          className="absolute"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ 
            opacity: [0.3, 0.6, 0.3],
            scale: [1, 1.2, 1],
            x: [0, 30, 0],
            y: [0, -30, 0]
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            delay: index * 1.5,
            ease: "easeInOut"
          }}
          style={{
            top: `${20 + index * 25}%`,
            left: `${10 + index * 30}%`,
          }}
        >
          <Icon className="h-8 w-8 text-blue-400/30" />
        </motion.div>
      ))}

      {/* Glowing Orbs */}
      {[...Array(3)].map((_, index) => (
        <motion.div
          key={`orb-${index}`}
          className="absolute w-32 h-32 rounded-full bg-blue-500/5 blur-2xl"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
            x: [0, 50, 0],
            y: [0, -50, 0]
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            delay: index * 2,
            ease: "easeInOut"
          }}
          style={{
            right: `${20 + index * 25}%`,
            bottom: `${20 + index * 20}%`,
          }}
        />
      ))}
    </div>
  );
};

export default FloatingElements;