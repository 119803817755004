import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calculator, DollarSign, TrendingUp, Users, Plus, Minus } from 'lucide-react';
import { cn } from '../../../utils/cn';

const LotCalculator = () => {
  const [lots, setLots] = useState<number>(10); // Start with 10 lots
  const [clients, setClients] = useState<number>(1);
  const commissionRate = 4; // $4 per lot

  const handleLotsChange = (increment: boolean) => {
    setLots(prev => Math.max(5, prev + (increment ? 5 : -5))); // Increment/decrement by 5, minimum 5 lots
  };

  const calculateEarnings = () => {
    const monthlyEarnings = lots * clients * commissionRate;
    const yearlyEarnings = monthlyEarnings * 12;
    return { monthly: monthlyEarnings, yearly: yearlyEarnings };
  };

  const earnings = calculateEarnings();

  return (
    <div className="relative group">
      {/* Ambient glow */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
      
      <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 group-hover:border-blue-500/50">
        {/* Header */}
        <div className="flex items-center gap-3 mb-8">
          <div className="p-2 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-lg">
            <Calculator className="h-6 w-6 text-blue-400" />
          </div>
          <h3 className="text-xl font-semibold text-white">Earnings Calculator</h3>
        </div>

        <div className="space-y-8">
          {/* Input Fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Number of Clients */}
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Number of Active Clients
              </label>
              <div className="relative">
                <div className="absolute left-4 top-1/2 -translate-y-1/2">
                  <Users className="h-5 w-5 text-gray-500" />
                </div>
                <input
                  type="number"
                  min="1"
                  value={clients}
                  onChange={(e) => setClients(Math.max(1, parseInt(e.target.value) || 1))}
                  className={cn(
                    "w-full bg-gray-900/50 border border-gray-700",
                    "rounded-lg pl-12 pr-4 py-3 text-white",
                    "focus:outline-none focus:ring-2 focus:ring-blue-500/50",
                    "transition-all duration-200"
                  )}
                />
              </div>
            </div>

            {/* Lots per Client */}
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Lots per Client (Monthly)
              </label>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => handleLotsChange(false)}
                  className={cn(
                    "p-2 rounded-lg bg-gray-900/50 border border-gray-700",
                    "hover:bg-gray-800 hover:border-blue-500/50",
                    "transition-all duration-200"
                  )}
                >
                  <Minus className="h-5 w-5 text-gray-400" />
                </button>
                
                <div className="relative flex-1">
                  <div className="absolute left-4 top-1/2 -translate-y-1/2">
                    <TrendingUp className="h-5 w-5 text-gray-500" />
                  </div>
                  <input
                    type="number"
                    value={lots}
                    readOnly
                    className={cn(
                      "w-full bg-gray-900/50 border border-gray-700",
                      "rounded-lg pl-12 pr-4 py-3 text-white text-center",
                      "focus:outline-none focus:ring-2 focus:ring-blue-500/50",
                      "transition-all duration-200"
                    )}
                  />
                </div>

                <button
                  onClick={() => handleLotsChange(true)}
                  className={cn(
                    "p-2 rounded-lg bg-gray-900/50 border border-gray-700",
                    "hover:bg-gray-800 hover:border-blue-500/50",
                    "transition-all duration-200"
                  )}
                >
                  <Plus className="h-5 w-5 text-gray-400" />
                </button>
              </div>
            </div>
          </div>

          {/* Results */}
          <div className="space-y-4">
            {/* Monthly Earnings */}
            <div className="bg-gray-900/50 rounded-lg p-6 border border-gray-700">
              <div className="flex items-center justify-between mb-2">
                <span className="text-gray-400">Monthly Earnings</span>
                <motion.div
                  key={earnings.monthly}
                  initial={{ scale: 1.2, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  className="flex items-center text-2xl font-bold text-green-400"
                >
                  <DollarSign className="h-6 w-6" />
                  {earnings.monthly.toLocaleString()}
                </motion.div>
              </div>
              <div className="text-sm text-gray-500">
                Based on {clients} client{clients > 1 ? 's' : ''} trading {lots} lot{lots > 1 ? 's' : ''} each
              </div>
            </div>

            {/* Yearly Projection */}
            <div className="bg-gray-900/50 rounded-lg p-6 border border-gray-700">
              <div className="flex items-center justify-between mb-2">
                <span className="text-gray-400">Yearly Projection</span>
                <motion.div
                  key={earnings.yearly}
                  initial={{ scale: 1.2, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  className="flex items-center text-2xl font-bold text-blue-400"
                >
                  <DollarSign className="h-6 w-6" />
                  {earnings.yearly.toLocaleString()}
                </motion.div>
              </div>
              <div className="text-sm text-gray-500">
                Estimated annual earnings at current rate
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LotCalculator;