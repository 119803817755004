import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign } from 'lucide-react';
import { GradientText } from '../ui/GradientText';
import LotCalculator from './calculator/LotCalculator';

const ReferralCommissions = () => {
  return (
    <div className="mb-24">
      <div className="text-center mb-12">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl md:text-4xl font-bold text-white mb-4"
        >
          Commission <GradientText>Structure</GradientText>
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto"
        >
          Simple and transparent commission structure with powerful earning potential
        </motion.p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-6xl mx-auto">
        {/* Commission Structure */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className="relative group h-full">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
            
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 group-hover:border-blue-500/50 text-center h-full">
              {/* Logo */}
              <img
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="HankoX"
                className="h-16 mx-auto mb-8"
              />

              {/* Commission Rate */}
              <div className="flex items-center justify-center w-20 h-20 mx-auto mb-6 rounded-full bg-gradient-to-br from-blue-500/20 to-purple-500/20">
                <DollarSign className="h-10 w-10 text-blue-400" />
              </div>

              <h3 className="text-3xl font-bold text-white mb-4">
                $4 <span className="text-gray-400">per lot</span>
              </h3>
              
              <div className="space-y-4 text-left max-w-lg mx-auto">
                <div className="flex items-start gap-3 group">
                  <div className="flex-shrink-0 p-1 bg-blue-500/10 rounded group-hover:bg-blue-500/20 transition-colors mt-1">
                    <DollarSign className="h-4 w-4 text-blue-500" />
                  </div>
                  <p className="text-gray-300">Fixed commission rate of $4 per lot traded by your referred clients</p>
                </div>
                <div className="flex items-start gap-3 group">
                  <div className="flex-shrink-0 p-1 bg-blue-500/10 rounded group-hover:bg-blue-500/20 transition-colors mt-1">
                    <DollarSign className="h-4 w-4 text-blue-500" />
                  </div>
                  <p className="text-gray-300">No minimum volume requirements or complex tiers</p>
                </div>
                <div className="flex items-start gap-3 group">
                  <div className="flex-shrink-0 p-1 bg-blue-500/10 rounded group-hover:bg-blue-500/20 transition-colors mt-1">
                    <DollarSign className="h-4 w-4 text-blue-500" />
                  </div>
                  <p className="text-gray-300">Earn commissions from all trading activities of your referred clients</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Calculator */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <LotCalculator />
        </motion.div>
      </div>
    </div>
  );
};

export default ReferralCommissions;