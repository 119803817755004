import React from 'react';
import { motion } from 'framer-motion';
import InfluencerCard from './InfluencerCard';

const influencers = [
  {
    name: 'Trade with Pat',
    handle: 'tradewithpat',
    bio: 'Professional forex trader and mentor with over 8 years of experience. Join my community to learn proven trading strategies and receive daily market analysis.',
    photo: 'https://trustedsignals.com/wp-content/uploads/2024/12/Screenshot-2024-12-23-160527.png',
    metrics: {
      followers: '100K+',
      experience: '8+ Years',
      signals: '1000+'
    },
    social: {
      instagram: 'https://instagram.com/tradewithpat',
      youtube: 'https://youtube.com/@tradewithpat',
      twitter: 'https://twitter.com/tradewithpat'
    },
    referralLink: '/tradewithpat'
  }
];

const InfluencerGrid = () => {
  return (
    <div className="mb-24">
      <h2 className="text-3xl font-bold text-white mb-12 text-center">Featured Trading Partner</h2>
      <div className="max-w-2xl mx-auto">
        {influencers.map((influencer, index) => (
          <motion.div
            key={influencer.handle}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <InfluencerCard {...influencer} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default InfluencerGrid;