import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, GraduationCap, Clock, Users, Award, Bell } from 'lucide-react';
import { GradientText } from '../ui/GradientText';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const features = [
  {
    icon: Clock,
    title: '20+ Hours',
    description: 'Course Content'
  },
  {
    icon: Users,
    title: 'Live Sessions',
    description: 'With Experts'
  },
  {
    icon: Award,
    title: 'Certification',
    description: 'Upon Completion'
  },
  {
    icon: GraduationCap,
    title: '6 Courses',
    description: 'Full Program'
  }
];

const EducationHero = () => {
  return (
    <div className="text-center mb-24">
      {/* Header Content */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="inline-flex items-center bg-blue-500/10 text-blue-400 px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
      >
        <BookOpen className="h-4 w-4 mr-2" />
        HankoX Trading Academy
      </motion.div>

      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="text-4xl md:text-6xl font-bold text-white mb-6"
      >
        Master Trading with{' '}
        <GradientText>Expert Guidance</GradientText>
      </motion.h1>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="text-gray-400 text-lg max-w-2xl mx-auto mb-12"
      >
        Comprehensive courses designed to help you master the HankoX platform and develop 
        professional trading skills. Get notified when our courses launch.
      </motion.p>

      {/* Coming Soon Section */}
      <motion.div 
        className="relative max-w-3xl mx-auto mb-16"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.3 }}
      >
        {/* Glowing background */}
        <div className="absolute -inset-4 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 rounded-2xl blur-xl" />
        
        <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
          {/* Coming Soon Text */}
          <div className="text-center mb-8">
            <motion.div
              className="text-3xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400"
              animate={{
                backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                ease: "linear"
              }}
            >
              Coming Soon
            </motion.div>
          </div>

          {/* Progress Indicator */}
          <div className="max-w-md mx-auto mb-8">
            <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
              <motion.div
                className="h-full bg-gradient-to-r from-blue-500 to-purple-500"
                animate={{
                  width: ['0%', '100%'],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
            </div>
          </div>

          {/* Feature Grid */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center mb-8">
            {features.map((feature, index) => (
              <div key={index} className="p-4">
                <feature.icon className="h-8 w-8 text-blue-400 mx-auto mb-2" />
                <div className="text-white font-medium">{feature.title}</div>
                <div className="text-sm text-gray-400">{feature.description}</div>
              </div>
            ))}
          </div>

          {/* CTA Button */}
          <Button
            href={TRADING_URL}
            size="lg"
            icon={Bell}
            className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
          >
            Notify When Available
          </Button>
          
          <p className="text-gray-400 text-sm mt-4">
            We'll notify you as soon as our courses are ready
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default EducationHero;