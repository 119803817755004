import React from 'react';
import { motion } from 'framer-motion';
import MagnoFxLogo from './logos/MagnoFxLogo';
import HankoTradeLogo from './logos/HankoTradeLogo';

const PartnershipLogos = () => {
  return (
    <div className="flex items-center justify-center gap-8 mb-12">
      <MagnoFxLogo />
      
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400"
      >
        ×
      </motion.div>

      <HankoTradeLogo />
    </div>
  );
};

export default PartnershipLogos;