import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../../utils/cn';
import MTX4NotificationBadge from './MTX4NotificationBadge';
import MTX4NotificationContent from './MTX4NotificationContent';
import MTX4NotificationImage from './MTX4NotificationImage';

const MTX4Notification = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative mb-8" // Reduced margin from mb-16 to mb-8
    >
      {/* Background glow effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 rounded-lg blur-xl opacity-50" />
      
      {/* Card container */}
      <div className={cn(
        "relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 md:p-8", // Adjusted padding
        "border border-gray-700/50"
      )}>
        {/* Badge */}
        <div className="mb-6"> {/* Reduced margin */}
          <MTX4NotificationBadge />
        </div>
        
        {/* Content Grid */}
        <div className="grid lg:grid-cols-2 gap-6 md:gap-8 items-center">
          <MTX4NotificationContent />
          <MTX4NotificationImage />
        </div>
      </div>
    </motion.div>
  );
};

export default MTX4Notification;