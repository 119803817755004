import React from 'react';
import { motion } from 'framer-motion';

interface VideoBackdropProps {
  onClose: () => void;
}

const VideoBackdrop: React.FC<VideoBackdropProps> = ({ onClose }) => {
  const handleClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="fixed inset-0 bg-black/90 backdrop-blur-sm"
      onClick={handleClick}
    />
  );
};

export default VideoBackdrop;