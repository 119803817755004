import React from 'react';
import { motion } from 'framer-motion';

const HeroLogo = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="mb-8"
    >
      <img 
        src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
        alt="HankoX"
        className="h-12 mx-auto"
      />
    </motion.div>
  );
};

export default HeroLogo;