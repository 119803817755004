import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';

interface ChristmasFeatureCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  delay?: number;
}

const ChristmasFeatureCard: React.FC<ChristmasFeatureCardProps> = ({
  icon: Icon,
  title,
  description,
  delay = 0
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="group relative"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-green-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
      <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-red-500/20 hover:border-green-500/50">
        <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-red-500/20 to-green-500/20 mb-6">
          <Icon className="h-6 w-6 text-red-400 group-hover:text-green-400 transition-colors" />
        </div>
        <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-green-400 transition-colors">{title}</h3>
        <p className="text-gray-400">{description}</p>
        
        {/* Christmas decoration */}
        <div className="absolute -top-2 -right-2 w-4 h-4 bg-red-500 rounded-full animate-pulse" />
      </div>
    </motion.div>
  );
};

export default ChristmasFeatureCard;