import React from 'react';
import { motion } from 'framer-motion';

const CopyTradingImage = () => {
  return (
    <div className="relative">
      <div className="relative z-10">
        <div className="relative rounded-2xl overflow-hidden shadow-2xl">
          <img
            src="https://trustedsignals.com/wp-content/uploads/2024/12/Screenshot-2024-12-13-202518.png"
            alt="Copy Trading Platform"
            className="w-full h-auto rounded-2xl transform hover:scale-105 transition-transform duration-500"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 mix-blend-overlay" />
        </div>
      </div>
      
      {/* Decorative elements */}
      <div className="absolute -inset-4 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-3xl opacity-50 rounded-full" />
      <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-blue-500/20 to-purple-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
      <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-purple-500/20 to-blue-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(59,130,246,0.1)_1px,transparent_1px)] bg-[size:20px_20px]" />
    </div>
  );
};

export default CopyTradingImage;