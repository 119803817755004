import React from 'react';
import { motion } from 'framer-motion';
import { ArrowUpCircle, ArrowDownCircle, Shield, Target, AlertTriangle } from 'lucide-react';
import { GradientText } from '../../ui/GradientText';

const StopLossTakeProfit = () => {
  return (
    <section id="stop-loss-take-profit" className="scroll-mt-20">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
          <GradientText>Risk Management</GradientText> with Stop Loss & Take Profit
        </h2>
        <p className="text-gray-400 text-lg">
          Learn how to protect your trades and secure profits with proper risk management
        </p>
      </motion.div>

      {/* Main Concepts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        {/* Stop Loss Section */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          className="relative group"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-pink-500/20 rounded-xl blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
          
          <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 h-full">
            <div className="flex items-center gap-4 mb-6">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-red-500/20 to-pink-500/20">
                <Shield className="h-6 w-6 text-red-400" />
              </div>
              <h3 className="text-xl font-semibold text-white">Stop Loss</h3>
            </div>
            
            <p className="text-gray-300 mb-6">
              A Stop Loss order helps limit potential losses by automatically closing your position 
              if the market moves against you.
            </p>

            <div className="space-y-4">
              <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-700">
                <h4 className="text-white font-medium mb-2">Example:</h4>
                <div className="space-y-2 font-mono text-sm">
                  <p className="text-blue-400">Buy EUR/USD at 1.2000</p>
                  <p className="text-red-400">Stop Loss at 1.1950 (-50 pips)</p>
                </div>
              </div>

              <div className="flex items-start gap-3 text-gray-300">
                <AlertTriangle className="h-5 w-5 text-yellow-400 mt-1" />
                <p className="text-sm">Never trade without a Stop Loss - it's your safety net against large losses</p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Take Profit Section */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          className="relative group"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-green-500/20 to-emerald-500/20 rounded-xl blur-xl opacity-50 group-hover:opacity-100 transition-opacity" />
          
          <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 h-full">
            <div className="flex items-center gap-4 mb-6">
              <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-green-500/20 to-emerald-500/20">
                <Target className="h-6 w-6 text-green-400" />
              </div>
              <h3 className="text-xl font-semibold text-white">Take Profit</h3>
            </div>
            
            <p className="text-gray-300 mb-6">
              A Take Profit order automatically closes your position when it reaches your desired 
              profit target, securing your gains.
            </p>

            <div className="space-y-4">
              <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-700">
                <h4 className="text-white font-medium mb-2">Example:</h4>
                <div className="space-y-2 font-mono text-sm">
                  <p className="text-blue-400">Buy EUR/USD at 1.2000</p>
                  <p className="text-green-400">Take Profit at 1.2100 (+100 pips)</p>
                </div>
              </div>

              <div className="flex items-start gap-3 text-gray-300">
                <Target className="h-5 w-5 text-green-400 mt-1" />
                <p className="text-sm">Set realistic Take Profit levels based on market conditions and analysis</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Risk Management Tips */}
      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
        <h3 className="text-xl font-semibold text-white mb-6">Risk Management Guidelines</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            {
              icon: Shield,
              title: 'Risk per Trade',
              description: 'Never risk more than 1-2% of your account balance on a single trade'
            },
            {
              icon: Target,
              title: 'Risk-Reward Ratio',
              description: 'Aim for a minimum risk-reward ratio of 1:2 or higher'
            },
            {
              icon: AlertTriangle,
              title: 'Position Sizing',
              description: 'Calculate position size based on your stop loss and risk tolerance'
            }
          ].map((tip, index) => (
            <div key={index} className="flex items-start gap-3">
              <div className="flex-shrink-0 p-2 bg-blue-500/10 rounded-lg">
                <tip.icon className="h-5 w-5 text-blue-400" />
              </div>
              <div>
                <h4 className="text-white font-medium mb-1">{tip.title}</h4>
                <p className="text-gray-400 text-sm">{tip.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StopLossTakeProfit;