import React from 'react';
import { motion } from 'framer-motion';
import { Users, Star, Shield, Gift } from 'lucide-react';
import { GradientText } from '../ui/GradientText';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const HeroSection = () => {
  return (
    <div className="text-center mb-16">
      {/* Badges */}
      <div className="flex flex-wrap justify-center gap-4 mb-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20"
        >
          <Users className="h-4 w-4 mr-2" />
          100K+ Active Traders
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="inline-flex items-center bg-green-500/10 text-green-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-green-500/20"
        >
          <Star className="h-4 w-4 mr-2" />
          Ultra-Low Spreads
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="inline-flex items-center bg-purple-500/10 text-purple-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-purple-500/20"
        >
          <Gift className="h-4 w-4 mr-2" />
          200% Holiday Bonus
        </motion.div>
      </div>

      {/* Main Title */}
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="text-4xl md:text-7xl font-bold text-white mb-6"
      >
        Trade with <GradientText>Pat</GradientText>
      </motion.h1>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="text-gray-300 text-lg md:text-xl max-w-3xl mx-auto mb-12"
      >
        Join my exclusive trading community at HankoX. Experience professional-grade trading with 
        ultra-low spreads, advanced platform features, and 24/7 support.
      </motion.p>

      {/* CTA Buttons */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="flex flex-col sm:flex-row items-center justify-center gap-4"
      >
        <Button
          href={TRADING_URL}
          size="lg"
          icon={Gift}
          className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
        >
          Start Trading Now
        </Button>
        <Button
          href={TRADING_URL}
          variant="secondary"
          size="lg"
          icon={Shield}
          className="w-full sm:w-auto min-w-[200px]"
        >
          Open Demo Account
        </Button>
      </motion.div>
    </div>
  );
};

export default HeroSection;