import React from 'react';
import { motion } from 'framer-motion';
import { Youtube } from 'lucide-react';
import { GradientText } from '../ui/GradientText';

const YouTubeSection = () => {
  return (
    <div className="mb-16">
      <div className="text-center mb-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center bg-red-500/10 text-red-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-red-500/20 mb-6"
        >
          <Youtube className="h-4 w-4 mr-2" />
          Educational Content
        </motion.div>

        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-3xl font-bold text-white mb-4"
        >
          Learn to Trade with <GradientText>Pat</GradientText>
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto mb-8"
        >
          Subscribe to my YouTube channel for educational content, market insights, and professional trading strategies.
        </motion.p>

        {/* Channel Link */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <a
            href="https://www.youtube.com/@TradewithPat"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-red-600 to-red-700 text-white rounded-lg font-medium hover:from-red-700 hover:to-red-800 transition-all duration-300"
          >
            Subscribe on YouTube
            <Youtube className="h-5 w-5" />
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default YouTubeSection;